import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';
import GlobalStyles from '../../../../styles/GlobalStyles';

export const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'row',
});

export const styles = createStyles({
  modal: {
    ...GlobalStyles.modal,
    p: 5,
    width: 450,
  },
  warningIcon: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
  image: {
    width: 80,
    maxWidth: '100%',
    verticalAlign: 'middle',
    display: 'inline-block',
    marginBottom: 10,
  },
});
