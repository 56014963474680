import FIELDS from './collaboratorFields';

const ERRORS_VALIDATIONS = {
  hasEmpty: ({ error, field }) => ({
    title: `A coluna ${field} possui${error ? ` ${error.length}` : ''} campo(s) em branco.`,
  }),
  hasDuplicate: ({ field }) => ({
    title: FIELDS[field].validations.hasDuplicate?.title,
  }),
  hasWrongMaxLength: ({ error, field }) => {
    const [validation] = error;
    return ({
      title: `A coluna ${field} deve ter no máximo ${validation.maxLength} caracteres. Há ${error.length} campo(s) com esse erro.`,
      message: `Os conteúdos da coluna ${field} abaixo são muito grandes:`,
    });
  },
  hasWrongMinLength: ({ error, field }) => {
    const [validation] = error;
    return ({
      title: `A coluna ${field} deve ter no máximo ${validation.minLength} caracteres. Há ${error.length} campo(s) com esse erro.`,
      message: `Os conteúdos da coluna ${field} abaixo são muito pequenos:`,
    });
  },
  hasWrongFormat: ({ field }) => ({
    title: FIELDS[field].validations.hasWrongFormat?.title || `Coluna ${field} está com o formato errado.`,
    message: FIELDS[field].validations.hasWrongFormat?.message || `Dados da coluna ${field} abaixo são inválidos:`,
  }),
};

export default ERRORS_VALIDATIONS;
