import createStyles from '@mui/styles/createStyles';
import COLORS from './Colors';

const GlobalStyles = createStyles({
  // Columns
  justifyContentColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  justifyContentColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  justifyContentColumnFlexEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  // Rows
  justifyContentRowStart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  justifyContentRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  justifyContentRowSpaceEvenly: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  justifyContentRowFlexEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  justifyContentRowFlexEndAlignCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  textCenter: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  // Others
  containerFullPageSize: {
    width: { xs: '80%', md: '50%' },
  },
  textDcUnderline: {
    textDecoration: 'underline',
  },
  textDcNone: {
    textDecoration: 'none',
  },
  containerFullPageWithoutBackButton: {
    marginTop: { xs: '40%', sm: '60px' },
  },
  smallMarginTop: {
    marginTop: 10,
  },
  mediumMarginTop: {
    marginTop: 20,
  },
  xsPaddinBottom: {
    paddingBottom: 5,
  },
  smallPaddingTop: {
    paddingTop: 10,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: COLORS.WHITE,
    boxShadow: 24,
    p: 4,
  },
  iconBorderThickness: {
    stroke: COLORS.WHITE, // the background color
    strokeWidth: 0.6,
  },
});

export default GlobalStyles;
