import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';
import ReactS3Uploader from 'react-s3-uploader';
import COLORS from '../../styles/Colors';

export const UploadLabel = styled('label')({
  padding: '6px 12px',
  marginTop: 5,
  borderRadius: 5,
  backgroundColor: COLORS.WHITE,
  color: COLORS.SECONDARY,
  borderColor: COLORS.SECONDARY_LIGHT,
  fontSize: 12,
  textAlign: 'center',
  textDecoration: 'none',
  textTransform: 'none',
  minWidth: 64,
  boxSizing: 'border-box',
  lineHeight: 1.75,
  borderStyle: 'solid',
  borderWidth: 1,
  '&:hover': {
    cursor: 'pointer',
  },
});

export const UploadInput = styled(ReactS3Uploader)({
  display: 'none',
});

export const Img = styled('img')({
  width: 70,
  marginRight: 'auto',
  marginLeft: 'auto',
  display: 'inline-block',
});

export const AttachmentContainer = styled('div')({
  display: 'flex',
});

export const AttachmentRemove = styled('button')({
  color: COLORS.SECONDARY_LIGHT,
  fontSize: 12,
  marginTop: 10,
  textDecoration: 'underline',
  cursor: 'pointer',
  border: 'none',
  backgroundColor: COLORS.WHITE,
});

export const styles = createStyles({
  requiredFields: {
    marginTop: 10,
    marginBottom: 20,
  },
  inputLabel: {
    marginTop: 30,
  },
  input: {
    width: '100%',
  },
  submitButton: {
    marginTop: 20,
    textAlign: 'center',
  },
  attachmentName: {
    marginTop: 10,
    marginRight: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    left: '10%',
  },
  modalBox: {
    backgroundColor: COLORS.WHITE,
    boxShadow: `0 2px 10px 0 ${COLORS.SECONDARY}`,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    borderRadius: 10,
  },
  modalTitle: {
    marginTop: 10,
  },
  modalDescription: {
    marginTop: 20,
    marginBottom: 20,
  },
});
