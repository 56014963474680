import React from 'react';
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import styles from './styles';

/**
 * @param {{ severity: 'success' | 'error' }} props
*/

export default function ToastMessage(props) {
  const {
    open,
    handleOpen,
    text,
    severity,
    autoHideDuration,
    icon,
  } = props;
  const cleanedProps = omit(props, ['handleOpen', 'autoHideDuration']);

  // eslint-disable-next-line no-shadow
  const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" sx={styles.alert} {...props} />
  ));

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    handleOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={severity}
        color={severity ? null : 'secondary_light'}
        icon={icon}
        {...cleanedProps}
      >
        {text}
      </Alert>
    </Snackbar>
  );
}

ToastMessage.defaultProps = {
  autoHideDuration: 3000,
};

ToastMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
  severity: PropTypes.oneOf(['success', 'error']),
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
};
