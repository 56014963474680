import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/css/material-dashboard-react.css';
import { store, persistor } from './redux/store/index';

import App from './App';
import Loading from './components/Loading';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<Loading />}
      persistor={persistor}
    >
      <App/>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
