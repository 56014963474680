import api from './Api';
import getAuthToken from './helpers';

class MessageService {
  constructor() {
    this.api = api;
  }

  sendMessage(payload) {
    return this.api.post('/messages', payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  getMessagesList(page, paginate = 10, status = 'active', order = 'DESC', startDate, finalDate, path = 'general') {
    const url = `/messages/${path}/admin?page=${page}&paginate=${paginate}&status=${status}&order=${order}&startDate=${startDate}&finalDate=${finalDate}`;
    return this.api.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  deleteMessage(messageId) {
    const url = `/messages/${messageId}/delete`;
    // TODO: Auth token should be in the middleware!
    return this.api.put(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default MessageService;
