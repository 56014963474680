import PropTypes from 'prop-types';
import Text from '../Text';
import { DefaultContainer, styles } from './styles';

/**
 * @param {{ color: 'primary' | 'secondary' | 'danger' }} props
 * @param {{ variant: 'label' | 'hintText' }} props
*/

export default function Tag({ color, children, variant }) {
  const getStyles = () => {
    if (color === 'primary') return styles.primary;
    if (color === 'secondary') return styles.secondary;
    return null;
  };
  const stylesContainer = getStyles();

  if (color === 'danger') {
    return (
      <Text variant={variant} component="span" style={styles.danger}>
        {children}
      </Text>
    );
  }

  // When color is: primary or secondary
  return (
    <DefaultContainer style={stylesContainer}>
      <Text variant={variant} component='span'>
        {children}
      </Text>
    </DefaultContainer>
  );
}

Tag.defaultProps = {
  color: 'primary',
  variant: 'label',
};

Tag.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  variant: PropTypes.oneOf(['label', 'hintText']),
};
