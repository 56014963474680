import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

export const Img = styled('img')({
  verticalAlign: 'middle',
  display: 'inline-block',
  width: 60,
});

const styles = createStyles({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
  },
});

export default styles;
