import api from './Api';
import getAuthToken from './helpers';

class ComplaintsService {
  constructor() {
    this.api = api;
  }

  getComplaints(page = 1, paginate = 10, status = undefined, order = 'DESC', startDate, finalDate) {
    const url = `/complaints/get-complaints?page=${page}&paginate=${paginate}&status=${status}&order=${order}&startDate=${startDate}&finalDate=${finalDate}`;
    return this.api.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  async resolveComplaint(complaintId, dispatch) {
    const url = `/complaints/${complaintId}/resolve-status-complaint`;
    return this.api.put(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }).then((result) => {
      dispatch({ type: 'RESOLVE_COMPLAINT' });
      return result;
    });
  }

  getAmountNewComplaints() {
    return this.api.get('/complaints/get-amount-new-complaints', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default ComplaintsService;
