import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { Pie } from 'react-chartjs-2';
import {
  DescriptionContainer,
  DescriptionIndicator,
  DescriptionHighlight,
  Img,
  styles,
} from './styles';
import CompanyService from '../../services/napoleon/CompanyService';
import COLORS from '../../styles/Colors';
import Text from '../Text';
import Link from '../Link';
import { emptyUsersIcon } from '../../assets/images/icons';

export default function CollaboratorAdhesionGraph({ calledByHome }) {
  const companyService = new CompanyService();
  const history = useHistory();
  const companyDispatch = useDispatch();
  const companyId = useSelector((state) => state.userReducer.decodeToken.user.companyId);
  const statistics = useSelector((state) => state.companyReducer.statistics);
  const [loading, setLoading] = useState(true);
  const [chartReference, setChartReference] = useState(null);
  const [defaultChartColors] = useState({
    totalActiveAuthenticatedColor: COLORS.PRIMARY,
    totalActiveUnauthenticatedColor: COLORS.PRIMARY_LIGHT,
  });
  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [
      {
        label: '',
        data: [],
      },
    ],
  });
  const [noCollaborators, setNoCollaborators] = useState(false);
  const stylesChart = calledByHome ? null : styles.bigChart;
  const variantTexts = calledByHome ? 'hintText' : 'paragraph';
  const gridItemXs = calledByHome ? 5 : 12;

  const goToListCollaboratorsPage = () => {
    history.push('/admin/collaborators');
  };

  const extractInformation = (totalType, field) => statistics.collaborators[totalType][field];

  const renderChartData = () => {
    if (
      extractInformation('totalActiveAuthenticated', 'value') > 0
      || extractInformation('totalActiveUnauthenticated', 'value') > 0
    ) {
      setNoCollaborators(false);

      setChartData({
        datasets: [
          {
            data: [
              extractInformation('totalActiveAuthenticated', 'value'),
              extractInformation('totalActiveUnauthenticated', 'value'),
            ],
            backgroundColor: [
              defaultChartColors.totalActiveAuthenticatedColor,
              defaultChartColors.totalActiveUnauthenticatedColor,
            ],
            borderWidth: 1,
          },
        ],
      });
    } else {
      setNoCollaborators(true);
    }
  };

  const getStatistics = () => {
    setLoading(true);
    companyService
      .getStatistics(companyId)
      .then(({ data }) => {
        companyDispatch({
          type: 'FIND_COMPANY_STATISTICS',
          statistics: data,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * The chart data is only updated after we have your instance,
   * it takes a long time to load the instance
   */
  useEffect(() => {
    if (chartReference) {
      renderChartData();
    }
  }, [chartReference]);

  useEffect(() => {
    getStatistics();
  }, []);

  const render = () => {
    if (loading) return null;

    if (noCollaborators) {
      return (
        <Grid item xs={12} style={styles.pageContainerNoCollaborators} container>
          <Img src={emptyUsersIcon} />
          <Text variant={variantTexts}>Não há nenhum usuário cadastrado.</Text>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2} style={styles.pageContainer}>
        <Grid item xs={gridItemXs} sm={5} style={stylesChart}>
          <Pie
            ref={setChartReference}
            data={chartData}
            options={{
              hover: { mode: null },
              maintainAspectRatio: false,
              plugins: {
                legend: false,
                tooltip: {
                  enabled: false,
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={gridItemXs} sm={7}>
          {!calledByHome && (
            <DescriptionContainer style={styles.justifyContentRowStart}>
              <Text variant={variantTexts} style={styles.spacingBottom}>
                <DescriptionHighlight>
                  {extractInformation('totalActive', 'value')}
                </DescriptionHighlight>
                colaboradores que já foram convidados para o Tinco
              </Text>
            </DescriptionContainer>)}

          <DescriptionContainer style={styles.justifyContentRowStart}>
            <DescriptionIndicator
              style={{ backgroundColor: defaultChartColors.totalActiveAuthenticatedColor }}
            />
            <Text variant={variantTexts}>
              <DescriptionHighlight>
                {extractInformation('totalActiveAuthenticated', 'percentage')}%
              </DescriptionHighlight>
              dos colaboradores já logaram no aplicativo.
            </Text>
          </DescriptionContainer>

          <DescriptionContainer style={styles.justifyContentRowStart}>
            <DescriptionIndicator
              style={{ backgroundColor: defaultChartColors.totalActiveUnauthenticatedColor }}
            />
            <Text variant={variantTexts}>
              <DescriptionHighlight>
                {extractInformation('totalActiveUnauthenticated', 'percentage')}%
              </DescriptionHighlight>
              dos colaboradores ainda não logaram no aplicativo.
            </Text>
          </DescriptionContainer>

          {!calledByHome && (
            <DescriptionContainer style={styles.justifyContentRowStart}>
              <Text variant={variantTexts} style={styles.spacingTop}>
                <Link onClick={() => goToListCollaboratorsPage()}>
                  Ver lista de colaboradores <NorthEastIcon fontSize='small' color='primary'/>
                </Link>
              </Text>
            </DescriptionContainer>
          )}
        </Grid>
      </Grid>
    );
  };

  return <>{render()}</>;
}

CollaboratorAdhesionGraph.defaultProps = {
  calledByHome: false,
};
