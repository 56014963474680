import HomePage from './views/Home';
import Mural from './views/Mural';
import DirectMessage from './views/DirectMessages';
import Statistics from './views/Statistics';
import NoPermission from './views/NoPermission';
import Collaborators from './views/Collaborators';
import Instructions from './views/Collaborators/components/ImportCollaborators/components/Instructions';
import ReportErrorFile from './views/Collaborators/components/ImportCollaborators/components/ReportErrorFile';
import SuccessImportFileMessage from './views/Collaborators/components/ImportCollaborators/components/SuccessImportFileMessage';
import ErrorImportFileMessage from './views/Collaborators/components/ImportCollaborators/components/ErrorImportFileMessage';
import MapCollaborators from './views/Collaborators/components/MapCollaborators';
import AddCollaborator from './views/Collaborators/components/AddCollaborator';
import PreviewList from './views/Collaborators/components/PreviewList';
import ListCollaborators from './views/ListCollaborators';
import Requests from './views/Requests';
import Complaints from './views/Complaints';
import Sectors from './views/Sectors';

import {
  boardIcon,
  cardIcon,
  complaintIcon,
  employeesIcon,
  homeIcon,
  messageIcon,
  requestIcon,
  sectorIcon,
  statisticsIcon,
} from './assets/images/iconsSideBar';

const dashboardRoutes = [
  {
    path: '/home',
    name: 'Home',
    icon: homeIcon,
    component: HomePage,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: 'https://empresa.volus.com',
    isExternalPath: true,
    name: 'Cartão de benefícios',
    icon: cardIcon,
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/mural',
    name: 'Mural',
    icon: boardIcon,
    component: Mural,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/direct-message',
    name: 'Mensagens diretas',
    component: Mural,
    icon: messageIcon,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/requests',
    name: 'Solicitações',
    icon: requestIcon,
    component: Requests,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/complaints',
    name: 'Denúncias',
    icon: complaintIcon,
    component: Complaints,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/statistics',
    name: 'Estatísticas',
    icon: statisticsIcon,
    component: Statistics,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/collaborators',
    name: 'Colaboradores',
    icon: employeesIcon,
    component: Collaborators,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/ListCollaborators',
    name: 'ListCollaborators',
    icon: employeesIcon,
    component: ListCollaborators,
    layout: '/admin',
    sidebar: false,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/collaborators-import',
    name: 'Importação de Colaboradores',
    icon: '',
    component: Instructions,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/collaborators',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/add-collaborator',
    name: 'Adicionar Colaborator',
    icon: '',
    component: AddCollaborator,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/collaborators',
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/create-message',
    name: 'Criar Mensagem',
    icon: '',
    component: DirectMessage,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/report-error-collaborators-import',
    name: 'Relatório de erro na validação dos Colaboradores',
    icon: '',
    component: ReportErrorFile,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/map-collaborators',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/success-collaborators-import-validate',
    name: 'Sucesso na validação de importação dos Colaboradores',
    icon: '',
    component: SuccessImportFileMessage,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/error-collaborators-import-validate',
    name: 'Erro na validação de importação dos Colaboradores',
    icon: '',
    component: ErrorImportFileMessage,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/access-denied',
    name: '',
    icon: '',
    component: NoPermission,
    layout: '/admin',
    sidebar: false,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/map-collaborators',
    name: '',
    icon: '',
    component: MapCollaborators,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/collaborators-import',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/preview-list',
    name: '',
    icon: '',
    component: PreviewList,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/map-collaborators',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/sectors',
    name: 'Setores',
    icon: sectorIcon,
    component: Sectors,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
];

export default dashboardRoutes;
