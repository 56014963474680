import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

/**
 * @param {{
 *  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'paragraph' | 'hintText' | 'label' | 'hintTextLabel'
 * }} props
*/

const Text = (props) => (
  <Typography component="div" {...props}>
    {props.children}
  </Typography>
);

Text.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'paragraph', 'hintText', 'label', 'hintTextLabel']).isRequired,
  color: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  align: PropTypes.string,
  children: PropTypes.node,
};

export default Text;
