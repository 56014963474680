import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import useStyles from './styles';
import COLORS from '../../../styles/Colors';

const DateInput = (props) => {
  const hasError = props.value && !isValid(new Date(props.value));
  const classes = useStyles({ ...props, hasError });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DatePicker
        {...props}
        inputFormat="dd/MM/yyyy"
        sx={{ svg: { color: COLORS.PRIMARY } }}
        slotProps={{
          textField: {
            variant: 'filled',
            placeholder: props.placeholderText || 'dd/mm/aaaa',
            className: classes.input,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
