import styled from '@mui/system/styled';
import ButtonBase from '@mui/material/ButtonBase';
import createStyles from '@mui/styles/createStyles';
import COLORS from '../../styles/Colors';

export const Container = styled(ButtonBase)((props) => ({
  display: 'flex',
  borderWidth: 1,
  border: `1px solid ${COLORS.PRIMARY}`,
  borderRadius: 20,
  padding: '2px 10px',
  marginRight: 10,
  backgroundColor: props.selected ? COLORS.PRIMARY : COLORS.WHITE,
  '&:hover': {
    backgroundColor: props.selected ? COLORS.PRIMARY : COLORS.LIGHT,
  },
}));

export const styles = createStyles({
  deselected: {
    color: COLORS.BLACK,
  },
  selected: {
    color: COLORS.WHITE,
  },
});
