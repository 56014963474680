import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
  card: {
    alignItems: 'center',
    minWidth: 200,
    width: 200,
    height: 220,
    cursor: 'default !important',
  },
  collaboratorName: {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    textAlign: 'center',
  },
});

export default styles;

export { styles as birthdayCollaboratorStyles };
