import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box } from '@mui/material';
import PropTypes from 'prop-types';

import { ButtonComponent, Text } from '../../../../components';
import { Input } from '../../../../components/Form';
import GlobalStyles from '../../../../styles/GlobalStyles';
import COLORS from '../../../../styles/Colors';
import styles from './styles';
import SectorsService from '../../../../services/napoleon/SectorsService';
import { showErrorToast, showSuccessToast } from '../../../../redux/reducers/toastReducer';

export default function ModalRegisterSector(props) {
  const {
    open,
    handleOpenModal,
    handleSubmitData,
    selectedSector,
    setSelectedSector,
  } = props;
  const sectorsService = new SectorsService();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.userReducer.decodeToken);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  function validate() {
    if (!open) return false;
    const hasError = !name || (name && name.trim() === '');
    setNameError(hasError);
    return hasError;
  }

  const closeModal = ({ submit }) => {
    setName('');
    setNameError(false);
    setLoading(false);
    if (submit) {
      handleSubmitData((prevValue) => !prevValue);
    }
    setSelectedSector(null);
    handleOpenModal(false);
  };

  const handleSubmit = () => {
    if (validate()) return;

    setLoading(true);

    const sector = {
      name: name.trim(),
      companyId: admin.user.companyId,
    };

    if (selectedSector) {
      sector.id = selectedSector.id;

      sectorsService.update(sector)
        .then(() => {
          dispatch(showSuccessToast('Setor atualizado com sucesso!'));
          closeModal({ submit: true });
        })
        .catch((error) => {
          dispatch(showErrorToast('Erro ao atualizar setor. Tente novamente mais tarde.'));
          throw error;
        })
        .finally(() => setLoading(false));
    } else {
      sectorsService.create(sector)
        .then(() => {
          dispatch(showSuccessToast('Setor cadastrado com sucesso!'));
          closeModal({ submit: true });
        })
        .catch((error) => {
          dispatch(showErrorToast('Erro ao cadastrar setor. Tente novamente mais tarde.'));
          throw error;
        })
        .finally(() => setLoading(false));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  function getOperationText() {
    return selectedSector ? 'Atualizar' : 'Cadastrar';
  }

  useEffect(() => validate(), [name]);

  useEffect(() => setName(selectedSector?.name || ''), [selectedSector]);

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modal}>
        <Text id="modal-modal-title" variant="h2" textAlign='start' marginBottom={2}>
          {getOperationText()} Setor
        </Text>

        <Text variant='h4'>Nome</Text>
        <Input
          placeholder="Insira o nome aqui"
          error={nameError}
          value={name}
          style={styles.input}
          onChange={(event) => setName(event.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus
          inputProps={{ maxLength: 60 }}
        />
        {nameError ? <Text variant='hintTextLabel' color={COLORS.RED} marginBottom={1}>Campo obrigatório</Text> : null}

        <div style={{ ...GlobalStyles.justifyContentRowSpaceEvenly, ...styles.marginTop }}>
          <ButtonComponent onClick={closeModal} color='primary_light'>
            Fechar
          </ButtonComponent>
          <ButtonComponent onClick={handleSubmit} color='primary' loading={loading}>
            { getOperationText() }
          </ButtonComponent>
        </div>
      </Box>
    </Modal>
  );
}

ModalRegisterSector.propTypes = {
  open: PropTypes.bool,
  handleOpenModal: PropTypes.func,
  handleSubmitData: PropTypes.func,
  selectedSector: PropTypes.object,
  setSelectedSector: PropTypes.func,
};
