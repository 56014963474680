import { store } from '../../redux/store';

const getAuthToken = () => {
  const state = store.getState();
  const authToken = state.userReducer.token;

  return authToken;
};

export default getAuthToken;
