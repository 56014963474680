import {
  FIND_MESSAGES,
  UPDATE_RENDERED_MESSAGE,
} from '../types';

const INITIAL_STATE = {
  messages: [],
  rowsPerPage: 0,
  renderedMessage: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_MESSAGES:
      return { ...state, messages: action.messages, rowsPerPage: action.rowsPerPage };
    case UPDATE_RENDERED_MESSAGE:
      return { ...state, renderedMessage: action.renderedMessage };
    default:
      return state;
  }
};
