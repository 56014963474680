import { styled } from '@mui/system';
import { Button, Box } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { drawerWidth } from '../components/Sidebar/styles';
import COLORS from '../styles/Colors';

export const MainPanel = styled(Box)({
  flexGrow: 1,
  paddingTop: 10,
  paddingLeft: 10,
  paddingRight: 5,
  paddingBottom: 20,
});

export const BackButton = withStyles({
  root: {
    color: COLORS.SECONDARY,
    marginLeft: '40px',
    textTransform: 'initial',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'normal',
    padding: '0px',
    '& > *:first-child': {
      '& > *:first-child': {
        marginRight: '0px',
      },
    },
  },
})(Button);

export const localStyles = createStyles({
  mainPanelResize: {
    ml: { md: `${drawerWidth}px` },
    width: { md: `calc(100% - ${drawerWidth}px)` },
    paddingTop: { md: 5 },
  },
  toolbar: {
    display: { md: 'none' },
  },
  headerBackButton: {
    height: 70,
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  backIcon: {
    marginRight: 10,
  },
});
