import Grid from '@mui/material/Grid';
import illustrationGear from '../../assets/gifs/gears.gif';
import { Img, styles } from './styles';
import Text from '../Text';

export default function ErrorState({ msg }) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={styles.container}
    >
      <Grid item xs={12}>
        <Img src={illustrationGear}></Img>
      </Grid>
      <Grid item xs={12}>
        <Text variant='h3' textAlign='center' color='secondary'>Ocorreu um erro</Text>
        <Text variant='hintText' marginTop={1}>{ msg }</Text>
      </Grid>
    </Grid>
  );
}
