import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormService from '../../services/napoleon/FormService';

const useValidateCollaboratorsFile = () => {
  const history = useHistory();
  const formService = new FormService();
  const formId = useSelector((state) => state.formReducer.id);
  const collaboratorsFormData = useSelector((state) => state.formReducer.collaboratorsFormData);
  const importErrors = useDispatch();
  const [loading, setLoading] = useState(false);

  function mergeFieldValidation(warning, error) {
    const differentValidationsHasMerged = [];
    let validations = {};

    // When an error occurred that was not validated by the backend
    if (!warning && !error) return validations;

    Object.keys(warning).map((warningField) => {
      Object.keys(error).map((errorField) => {
        if (warningField === errorField) {
          differentValidationsHasMerged.push(errorField);
          validations = Object.assign(validations, {
            [warningField]: { ...warning[errorField], ...error[errorField] },
          });
        } else if (!differentValidationsHasMerged.includes(errorField)) {
          validations = Object.assign(validations, { [errorField]: { ...error[errorField] } });
        }

        return error[errorField];
      });

      if (!differentValidationsHasMerged.includes(warningField)) {
        validations = Object.assign(validations, { [warningField]: { ...warning[warningField] } });
      }

      return warning[warningField];
    });

    if (Object.keys(warning).length <= 0) {
      Object.keys(error).map((errorField) => {
        if (!differentValidationsHasMerged.includes(errorField)) {
          validations = Object.assign(validations, { [errorField]: { ...error[errorField] } });
        }

        return error[errorField];
      });
    }

    return validations;
  }

  const validateColumnsCollaboratorsFile = async (selectedFields, ignoreWarnings = false) => {
    setLoading(true);

    const formData = new FormData();
    formData.set('collaboratorsFile', collaboratorsFormData.collaboratorsFile);
    formData.set('selectedFields', `{"selectedFields":${JSON.stringify(selectedFields)}}`);
    formData.set('ignoreWarnings', ignoreWarnings);

    let hasValidateFileError = false;
    let hasAnyFileError = false;

    return formService
      .validateColumnsCollaboratorsFile(formId, formData)
      .then(() => {
        hasValidateFileError = false;
        hasAnyFileError = false;
      })
      .catch((responseError) => {
        const { response } = responseError;

        if (response) {
          const {
            data: { warning, error },
          } = response;
          importErrors({
            type: 'FIND_IMPORT_ERRORS',
            importValidateErrors: {
              mergedFields: mergeFieldValidation(warning, error),
              warning,
              error,
            },
          });
          hasValidateFileError = true;
        } else {
          hasAnyFileError = true;
        }
        throw responseError;
      })
      .finally(() => {
        setLoading(false);

        if (hasValidateFileError) {
          history.push('/admin/report-error-collaborators-import');
        } else if (hasAnyFileError) {
          history.push('/admin/error-collaborators-import-validate');
        } else {
          history.push('/admin/success-collaborators-import-validate', {
            title: 'Seu arquivo foi importado com sucesso.',
            subTitle: 'Está tudo pronto para começar a comunicar com seu time de maneira eficiente!',
            buttonText: 'Finalizar',
          });
        }
      });
  };

  return { validateColumnsCollaboratorsFile, loading };
};

export default useValidateCollaboratorsFile;
