import {
  FIND_COMPANY_STATISTICS,
} from '../types';

const INITIAL_STATE = {
  statistics: {
    collaborators: {
      totalActive: {
        value: 0,
        percentage: 0,
      },
      totalActiveAuthenticated: {
        value: 0,
        percentage: 0,
      },
      totalActiveUnauthenticated: {
        value: 0,
        percentage: 0,
      },
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_COMPANY_STATISTICS:
      return { ...state, statistics: action.statistics };
    default:
      return state;
  }
};
