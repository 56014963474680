import axios from 'axios';
import api from './Api';
import getAuthToken from './helpers';

class AWSService {
  constructor() {
    this.api = api;
    this.axios = axios;
  }

  getSignedUrl(file) {
    const { name: fileName } = file;
    return this.api.get(`aws/s3/sign?fileName=${fileName}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  uploadFile(file, signedUrl) {
    return this.axios.put(signedUrl, file);
  }
}

export default AWSService;
