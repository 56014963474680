import styled from 'styled-components';
import Icon from '@mui/material/Icon';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import COLORS from '../../../../../../styles/Colors';

export const DescriptionContainer = styled.div`
  line-height: 21px;
  font-weight: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const DescriptionIconContainer = withStyles({
  root: {
    float: 'left',
    marginRight: '5px',
  },
})(Icon);

export const DescriptionIcon = styled.img`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescriptionDivider = styled.div`
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${COLORS.SECONDARY_LIGHT};
`;

const styles = createStyles({
  descriptionContainer: {
    backgroundColor: COLORS.LIGHT,
    padding: 20,
  },
});

export default styles;
