import PropTypes from 'prop-types';
import { darkLogo, lightLogo } from '../../assets/images/logos';

/**
* @param {{
*  mode: 'light' | 'dark'
* }} props
*/

const Logo = ({ mode, ...props }) => (
  <img
    src={mode === 'dark' ? darkLogo : lightLogo}
    width={110}
    height={28}
    {...props}
  />
);

Logo.defaultProps = {
  mode: 'dark',
};

Logo.propTypes = {
  mode: PropTypes.oneOf(['light', 'dark']),
};

export default Logo;
