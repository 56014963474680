import { Box, Chip, MenuItem } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import PropTypes from 'prop-types';
import Select from '../SelectInput';
import { styles, MenuProps } from './styles';

function getStyles(name, value, theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectInput(props) {
  const {
    array,
    value,
    setValue,
    firstOption,
  } = props;
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value: selectedOptions },
    } = event;

    if (selectedOptions.length === 0) return;

    // When user is trying to select another option but the default option is already selected
    if (selectedOptions.length > 1 && selectedOptions[0] === firstOption) {
      // Remove default option
      selectedOptions.splice(0, 1);
    }

    // When user is trying to select the default option but another option is already selected
    if (selectedOptions[selectedOptions.length - 1] === firstOption) {
      // Remove all options except the default option
      selectedOptions.splice(0, selectedOptions.length - 1);
    }

    setValue(
      typeof selectedOptions === 'string' ? selectedOptions.split(', ') : selectedOptions,
    );
  };

  return (
    <Select
      style={styles.select}
      id="id-multiple-select"
      multiple
      value={value}
      onChange={handleChange}
      renderValue={(selected) => (
        <Box sx={styles.box}>
          {selected.map((selectedValue) => (
            <Chip key={selectedValue} label={selectedValue} />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
    >
      <MenuItem value={firstOption} selected>
        <em>{firstOption}</em>
      </MenuItem>

      {array.map((item) => (
        <MenuItem
          key={item.id}
          value={item.name}
          style={getStyles(item.name, value, theme)}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
}

MultipleSelectInput.propTypes = {
  array: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func,
  firstOption: PropTypes.string,
};
