import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import useValidateCollaboratorsFile from '../../../../common/hooks/useValidateCollaboratorsFile';
import { ButtonComponent, Text, Loading } from '../../../../components';
import { ContainerFullPage } from '../../../../styles/components';
import GlobalStyles from '../../../../styles/GlobalStyles';
import { PreviewContainer } from './styles';

function PreviewList() {
  const listPreview = useSelector((state) => state.formReducer.listPreview);
  const selectedFields = useSelector((state) => state.formReducer.selectedFields);
  const history = useHistory();
  const { validateColumnsCollaboratorsFile, loading } = useValidateCollaboratorsFile();

  const handleValidate = () => validateColumnsCollaboratorsFile(selectedFields);

  const FieldColumnName = ({ fieldColumnName }) => {
    const fieldColumnsName = listPreview.filter((item) => item.field === fieldColumnName);
    if (fieldColumnsName.length > 0) {
      return (
        fieldColumnsName.map((e, i) => (
          <Text variant='hintText' key={i} lineHeight={2}>{e.value}</Text>
        ))
      );
    }

    if (!fieldColumnName) {
      return <Text variant='hintText' lineHeight={2}>Não foi mapeado</Text>;
    }

    return <></>;
  };

  if (loading) return <Loading title='Processando…' />;

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>Confirme as colunas mapeadas</Text>
      <Text variant='hintText' style={GlobalStyles.smallMarginTop}>
        Verifique se as informações do colaborador correspondem às colunas selecionadas.
      </Text>

      {selectedFields.map((prop, index) => (
        <PreviewContainer key={index}>
          <Text variant='h4' lineHeight={2}>{prop.formFieldName}</Text>
          <FieldColumnName fieldColumnName={prop.fieldColumnName} />
        </PreviewContainer>
      ))}

      <Grid container style={GlobalStyles.justifyContentRowSpaceEvenly}>
        <ButtonComponent
          variant="outlined"
          size='medium'
          style={GlobalStyles.mediumMarginTop}
          onClick={() => history.goBack()}
        >
          Retornar
        </ButtonComponent>

        <ButtonComponent
          size='medium'
          style={GlobalStyles.mediumMarginTop}
          onClick={handleValidate}
        >
          Está correto
        </ButtonComponent>
      </Grid>
    </ContainerFullPage>
  );
}

export default PreviewList;
