import { Switch, BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';

import Admin from './layouts/Admin';
import history from './common/history';
import AuthenticatedRoute from './common/AuthenticatedRoute';
import theme from './styles/theme';
import { ToastMessage } from './components';
import { hideToast } from './redux/reducers/toastReducer';

function App() {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.userReducer.decodeToken.user.userType);
  const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated);
  const sessionExpired = useSelector((state) => state.userReducer.sessionExpired);
  // TODO: Update the old places where the toast was not being used from redux
  const { toastReducer } = useSelector((state) => state);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <Switch>
              <AuthenticatedRoute
                path="/"
                component={Admin}
                appProps={{ isAuthenticated, userType, sessionExpired }}
              />
            </Switch>

            <ToastMessage
              open={toastReducer.open}
              handleOpen={() => dispatch(hideToast())}
              severity={toastReducer.type}
              text={toastReducer.text}
            />
          </ConnectedRouter>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
