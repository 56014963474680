import {
  FIND_IS_AUTHENTICATED,
  FIND_TOKEN,
  FIND_TOKEN_DECODE,
  RESET_USER_STATE,
  SESSION_EXPIRED,
} from '../types';

const INITIAL_STATE = {
  isAuthenticated: false,
  sessionExpired: false,
  token: '',
  decodeToken: {
    user: {
      id: null,
      cpf: null,
      email: null,
      name: null,
      lastName: null,
      userType: null,
      companyId: null,
    },
    iat: null,
    exp: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.isAuthenticated };
    case SESSION_EXPIRED:
      return { ...state, sessionExpired: action.sessionExpired };
    case FIND_TOKEN:
      return { ...state, token: action.token };
    case FIND_TOKEN_DECODE:
      return { ...state, decodeToken: action.decodeToken };
    case RESET_USER_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
