import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import { StyledCard } from './styles';

/**
 * @typedef {import('react').CSSProperties} CSSProperties
 */

/**
 * Card Component
 * @param {Object} props
 * @param {boolean} [props.disabled=false] - Indicates if the card is disabled
 * @param {CSSProperties} [props.style={}] - Inline CSS styles
 * @param {Object} [props.sx={}] - The MUI `sx` prop for system-based styling
 * @param {function} [props.onClick] - Callback function triggered when the card is clicked
 * @param {'div' | 'button'} [props.as='div'] - Defines the element type
 * @returns {JSX.Element}
 */

export default function Card(props) {
  const { as, children, ...rest } = props;
  const WrapperComponent = as === 'button' ? ButtonBase : 'div';

  return (
    <StyledCard {...rest} as={WrapperComponent}>{children}</StyledCard>
  );
}

Card.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.object,
  sx: PropTypes.object,
  onClick: PropTypes.func,
  as: PropTypes.oneOf(['div', 'button']),
};

Card.defaultProps = {
  disabled: false,
  style: {},
  sx: {},
  onClick: undefined,
  as: 'div',
};
