import { Route, Redirect } from 'react-router-dom';
import Login from '../views/Login/Login';
import routes from '../routes';

export default function AuthenticatedRoute({ component: C, appProps, ...rest }) {
  const {
    isAuthenticated, userType, sessionExpired,
  } = appProps;
  const {
    location: { pathname },
  } = rest;

  const render = (props) => {
    const hasPermissionToAccessRoute = () => {
      const routeConfig = routes.find((route) => `/admin${route.path}` === pathname);
      if (routeConfig && routeConfig.access && !routeConfig.access.includes(userType)) {
        return false;
      }
      return true;
    };
    if (!hasPermissionToAccessRoute(props)) {
      return (
          <div>
            <Redirect to="/admin/access-denied" />
          </div>
      );
    }
    if (isAuthenticated && !sessionExpired) {
      return <C {...props} {...appProps} />;
    }
    return (
      <div>
        <Route to="/login" component={Login} />
        <Redirect to="/login" />
      </div>
    );
  };

  return <Route {...rest} render={(props) => render(props)} />;
}
