import Select from '@mui/material/Select';
import useStyles from './styles';

export default function SelectInput({ error, children, ...props }) {
  const classes = useStyles({ error });

  return (
    <Select
      variant="standard"
      className={classes.selectOptions}
      classes={{ select: classes.selected }}
      inputProps={{
        classes: { icon: classes.icon },
      }}
      {...props}
    >
      {children}
    </Select>
  );
}
