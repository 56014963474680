import { createTheme } from '@mui/material';
import COLORS from './Colors';

const theme = createTheme({
  // light: will be calculated from palette.primary.main,
  // dark: will be calculated from palette.primary.main,
  // contrastText: will be calculated to contrast with palette.primary.main
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    primary_light: {
      main: COLORS.PRIMARY_LIGHT,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    secondary_light: {
      main: COLORS.SECONDARY_LIGHT,
    },
    light: {
      main: COLORS.WHITE,
    },
    danger: {
      main: COLORS.DANGER,
    },
    inactive_light: {
      main: COLORS.INACTIVE_LIGHT,
    },
    selection_status: {
      main: COLORS.SELECTION_STATUS,
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: {
      fontSize: 26,
      fontWeight: 'bold',
      color: COLORS.PRIMARY,
    },
    h2: {
      fontSize: 21,
      fontWeight: 'bold',
      color: COLORS.PRIMARY,
    },
    h3: {
      fontSize: 18,
      fontWeight: 'bold',
      color: COLORS.PRIMARY,
    },
    h4: {
      fontSize: 15,
      fontWeight: 'bold',
      color: COLORS.PRIMARY,
    },
    h5: {
      fontSize: 14,
      fontWeight: 'bold',
      color: COLORS.PRIMARY,
    },
    paragraph: {
      fontSize: 16,
      color: COLORS.BLACK,
    },
    hintText: {
      fontSize: 14,
      color: COLORS.SECONDARY_LIGHT,
    },
    label: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    hintTextLabel: {
      fontSize: 12,
      color: COLORS.SECONDARY_LIGHT,
    },
  },
});

export default theme;
