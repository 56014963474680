import { makeStyles } from '@mui/styles';
import COLORS from '../../../styles/Colors';

const useStyles = makeStyles({
  input: (props) => ({
    // root
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
      height: !props.label && 45,
    },

    // Adjusting the padding inside the input
    '& .MuiFilledInput-input': {
      paddingLeft: 0,
    },

    // borders
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: props.hasError ? COLORS.RED : COLORS.SECONDARY,
      borderWidth: 1,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: props.hasError ? COLORS.RED : COLORS.PRIMARY,
      borderWidth: 1,
    },
    '&:hover .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: props.hasError ? COLORS.RED : COLORS.PRIMARY,
      borderWidth: 1,
    },

    // label
    '& .MuiInputLabel-root': {
      transform: 'translate(0px, 25px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0px, 5px) scale(0.75)',
    },

    // svg icon button
    '& .MuiIconButton-root': {
      position: 'absolute',
      right: 11,
      transform: 'translate(2px, 7px) scale(1)',
      padding: '0',
    },
    '& .MuiSvgIcon-root': {},
  }),
});

export default useStyles;
