import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Grid,
  Pagination,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneIcon from '@mui/icons-material/Done';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import {
  Text,
  Loading,
  ImageHeader,
  EmptyList,
  Alert,
  RadioButton,
  Tag,
  ErrorState,
} from '../../components';
import { DateInput } from '../../components/Form';
import { styles } from './styles';
import '../../styles/table.css';
import { FlexDirectionRowCenter, TableRowStyled } from '../../styles/components';
import GlobalStyles from '../../styles/GlobalStyles';
import { complaintIcon } from '../../assets/images/iconsQuickAction';
import { emptyMessageIcon } from '../../assets/images/icons';
import ComplaintsService from '../../services/napoleon/ComplaintsService';
import StatusComplaintsService from '../../services/napoleon/StatusComplaints';
import { isValidDate } from '../../utils';
import ModalDetail from './components/ModalDetail';

export default function Complaints() {
  const complaintsDispatch = useDispatch();
  const complaintsService = new ComplaintsService();
  const statusComplaintsService = new StatusComplaintsService();
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  /* eslint-disable */
  const [radioOrderOptions, setRadioOrderOptions] = useState([
    { id: 1, text: 'Mais recentes', value: 'DESC', selected: true },
    { id: 2, text: 'Mais antigas', value: 'ASC', selected: false },
  ]);
  const [radioStatusOptions, setRadioStatusOptions] = useState(null);
  const radioStatusOptionsDefaultValue = { id: undefined, name: 'Todas', selected: true };
  /* eslint-enable */
  const [orderComplaints, setOrderComplaints] = useState(radioOrderOptions[0].value);
  const [statusComplaints, setStatusComplaints] = useState(null);
  /* Controls the exhibition of the Loading component */
  const [loading, setLoading] = useState(false);
  /* Used to guarantee that the functions getComplaints() and getStatusComplaints()
    will not be called more than once */
  let lookingForComplaints = false;
  let lookingForStatusComplaints = false;
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(1);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const [totalPages, setTotalPages] = useState(0);
  const [complaints, setComplaints] = useState(null);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    severity: '',
    snackbarText: '',
    duration: 3000,
    icon: '',
  });
  const [selectedComplaint, setSelectedComplaint] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuOptions = Boolean(anchorEl);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const handleClickOptions = (event) => setAnchorEl(event.currentTarget);
  const handleCloseOptions = () => setAnchorEl(null);

  const handleClickSnackbar = ({
    severity,
    snackbarText,
    duration,
    icon,
  }) => {
    setAlertOptions({
      severity,
      snackbarText,
      duration: duration || 3000,
      icon,
    });
    setOpenSnackBar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  // eslint-disable-next-line max-len
  const isComplaintSolved = () => selectedComplaint?.status?.id === statusComplaintsService.status.solved.id;

  // eslint-disable-next-line max-len
  const isComplaintAnonymous = () => selectedComplaint?.collaboratorId === 0 || !selectedComplaint?.collaborator;

  const getComplaints = () => {
    if (lookingForComplaints) return;

    lookingForComplaints = true;
    setLoading(true);

    const start = startDate ? moment(startDate).format('YYYY-MM-DD 00:00:01') : undefined;
    const end = finalDate ? moment(finalDate).format('YYYY-MM-DD 23:59:59') : undefined;

    complaintsService.getComplaints(page, 10, statusComplaints, orderComplaints, start, end)
      .then((response) => {
        setHasError(false);
        const { messages, pages } = response.data;
        setComplaints(messages);
        setTotalPages(pages);
      })
      .catch((error) => {
        setComplaints(null);
        setHasError(true);
        throw error;
      })
      .finally(() => {
        lookingForComplaints = false;
        if (!lookingForStatusComplaints) setLoading(false);
      });
  };

  const getStatusComplaints = () => {
    if (lookingForStatusComplaints) return;

    lookingForStatusComplaints = true;
    setLoading(true);

    statusComplaintsService.getStatus()
      .then((response) => {
        setHasError(false);
        const options = [];
        options.push(radioStatusOptionsDefaultValue);
        for (let i = 0; i < response.data.length; i++) {
          options.push(response.data[i]);
        }
        setRadioStatusOptions(options);
      })
      .catch((error) => {
        setRadioStatusOptions(null);
        setHasError(true);
        throw error;
      })
      .finally(() => {
        lookingForStatusComplaints = false;
        if (!lookingForComplaints) setLoading(false);
      });
  };

  const shouldNavigate = (event, complaint) => {
    const html = event.target.outerHTML.substring(0, 10);
    // When the user clicked in the More options Icon should navigate
    if (html.includes('<td') || html.includes('<span') || html.includes('<img')) {
      setSelectedComplaint(complaint);
      setOpenModalDetail(true);
    }
  };

  const collaboratorName = (complaint) => {
    if (complaint.identification === false) {
      return 'Anônimo(a)';
    }

    if (complaint.identification && complaint.collaborator) {
      const { name } = complaint.collaborator;
      return name || 'Nome Não cadastrado';
    }

    return null;
  };

  const resolveComplaint = (complaintId) => {
    complaintsService.resolveComplaint(complaintId, complaintsDispatch)
      .then((result) => {
        const complaintsTemp = [...complaints];
        const idx = complaintsTemp.findIndex((complaint) => complaint.id === selectedComplaint.id);
        complaintsTemp[idx] = result.data;
        setComplaints(complaintsTemp);
        handleClickSnackbar({ severity: 'success', snackbarText: 'Denúncia resolvida com sucesso!' });
      })
      .catch((error) => {
        handleClickSnackbar({ severity: 'error', snackbarText: 'Erro ao resolver denúncia. Tente novamente mais tarde' });
        throw error;
      });
  };

  const answerComplaint = (collaborator, updateComplaint = false) => {
    const complaintToUpdate = updateComplaint && selectedComplaint.id;
    history.push('/admin/create-message', { path: 'directMessage', collaborator, complaintToUpdate });
  };

  useEffect(() => {
    if (!startDate && !finalDate) {
      getComplaints();
      return;
    }

    if (!startDate || !finalDate) return;

    try {
      const startDateISOString = startDate.toISOString();
      const finalDateISOString = finalDate.toISOString();
      const yearStartDate = startDateISOString.substring(0, 10).split('-')[0];
      const yearFinalDate = finalDateISOString.substring(0, 10).split('-')[0];

      if (yearStartDate < 1000 || yearFinalDate < 1000) return;

      if (startDate.getTime() > finalDate.getTime()) {
        handleClickSnackbar({ severity: 'error', snackbarText: 'A data inicial não pode ser maior do que a data final!' });
        return;
      }

      if (isValidDate(startDate.toISOString()) && isValidDate(finalDate.toISOString())) {
        getComplaints();
      }
    } catch (error) {
      /* eslint-disable */
      return error;
      /* eslint-enable */
    }
  }, [startDate, finalDate]);

  useEffect(() => getComplaints(), [page, statusComplaints, orderComplaints]);

  useEffect(() => getStatusComplaints(), []);

  if (hasError) return <ErrorState msg='Recarregue a página para ver as denúncias.' />;

  if (loading) return <Loading />;

  return (
    <Container>
      <Grid item xs={12} style={GlobalStyles.justifyContentRowStart} marginBottom={2}>
        <FlexDirectionRowCenter>
          <ImageHeader src={complaintIcon} />
          <Text variant='h2'>Denúncias</Text>
        </FlexDirectionRowCenter>
      </Grid>

      <Grid container columnSpacing={2} rowSpacing={1} style={GlobalStyles.justifyContentRowCenter}>
        {/* Initial date */}
        <Grid item xs={6} sm={2} md={2} style={GlobalStyles.justifyContentRowStart}>
          <DateInput
            label='Data inicial'
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            disableFuture
            inputFormat='dd/MM/yyyy'
          />
        </Grid>

        {/* Final date */}
        <Grid item xs={6} sm={2} md={2} style={GlobalStyles.justifyContentRowStart}>
          <DateInput
            label="Data final"
            value={finalDate}
            onChange={(newValue) => setFinalDate(newValue)}
            inputFormat='dd/MM/yyyy'
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} sx={styles.spacingLeft}>
          <Text variant='hintText' style={GlobalStyles.xsPaddinBottom}>Período</Text>
          <Grid container style={GlobalStyles.justifyContentRowStart}>
            {
              radioOrderOptions.map((item, key) => (
                <RadioButton
                  text={item.text}
                  selected={item.selected}
                  key={key}
                  onClick={() => {
                    const options = [...radioOrderOptions];
                    if (options[key].selected) return;
                    options[key].selected = true;
                    setOrderComplaints(options[key].value);
                    setPage(1);
                    /* eslint-disable */
                    options.map((option, idx) => {
                      if (idx !== key)
                        option.selected = false;
                    })
                    /* eslint-enable */
                    setRadioOrderOptions(options);
                  }}
                />
              ))
            }
          </Grid>
        </Grid>

        { radioStatusOptions && radioStatusOptions.length > 0
          ? (
            <Grid item xs={12} sm={5} md={4}>
              <Text variant='hintText' style={GlobalStyles.xsPaddinBottom}>Status</Text>
              <Grid container style={GlobalStyles.justifyContentRowStart}>
                {
                  radioStatusOptions.map((item, key) => (
                    <RadioButton
                      text={item.name}
                      selected={item.selected}
                      key={key}
                      onClick={() => {
                        const options = [...radioStatusOptions];
                        if (options[key].selected) return;
                        options[key].selected = true;
                        setStatusComplaints(options[key].id);
                        setPage(1);
                        /* eslint-disable */
                        options.map((option, idx) => {
                          if (idx !== key)
                            option.selected = false;
                        })
                        /* eslint-enable */
                        setRadioStatusOptions(options);
                      }}
                    />
                  ))
                }
              </Grid>
            </Grid>
          ) : null
        }

        {
          complaints && complaints.length > 0
            ? (
              <>
                {/* Pagination */}
                <Grid container item>
                  <Grid item xs={12} style={GlobalStyles.justifyContentRowFlexEnd}>
                    <Pagination
                      boundaryCount={1}
                      siblingCount={1}
                      page={page}
                      onChange={handleChangePage}
                      count={totalPages}
                      color='primary'
                      size='small'
                    />
                  </Grid>
                </Grid>

                  {/* Table */}
                  <Grid container style={GlobalStyles.smallPaddingTop}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table aria-label="customized table">
                          <TableBody>
                          {/* eslint-disable */}
                            {
                              complaints.map((complaint, key) => (
                                <TableRowStyled key={key} onClick={(event) => shouldNavigate(event, complaint)}>
                                  <TableCell>{moment(complaint.createdAt).format('DD/MM/YYYY')} - {moment(complaint.createdAt).format('HH:mm')}</TableCell>
                                  <TableCell>{collaboratorName(complaint)}</TableCell>
                                  <TableCell>{complaint.subject}</TableCell>
                                  <TableCell><Tag color={complaint.status?.color}>{complaint.status?.name}</Tag></TableCell>
                                  <TableCell align='right'>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={openMenuOptions ? 'long-menu' : undefined}
                                      aria-expanded={openMenuOptions ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) => { handleClickOptions(event); setSelectedComplaint(complaint) }}
                                    >
                                      <MoreHorizIcon color='primary' fontSize='small' />
                                    </IconButton>
                                  </TableCell>
                                </TableRowStyled>
                              ))
                            }
                          {/* eslint-enable */}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Menu
                        id="long-menu"
                        MenuListProps={{ 'aria-labelledby': 'long-button' }}
                        anchorEl={anchorEl}
                        open={openMenuOptions}
                        onClose={handleCloseOptions}
                        PaperProps={{ style: styles.optionsPopover }}
                      >
                        <MenuItem
                          onClick={() => {
                            setOpenModalDetail(true);
                            handleCloseOptions();
                          }}
                        >
                          <InfoOutlinedIcon color='secondary_light' fontSize='small'/>&nbsp;
                          <Text variant='hintText'>Informações</Text>
                        </MenuItem>

                        { !isComplaintSolved() && !isComplaintAnonymous() && (
                          <MenuItem
                            onClick={() => {
                              answerComplaint(selectedComplaint.collaborator);
                              handleCloseOptions();
                            }}
                          >
                            <ReplyOutlinedIcon color='secondary_light' fontSize='small'/>&nbsp;
                            <Text variant='hintText'>Responder</Text>
                          </MenuItem>
                        )}

                        { !isComplaintSolved() && (
                          <MenuItem
                            onClick={() => {
                              resolveComplaint(selectedComplaint.id);
                              handleCloseOptions();
                            }}
                          >
                            <DoneIcon color='secondary_light' fontSize='small'/>&nbsp;
                            <Text variant='hintText'>Marcar como resolvido</Text>
                          </MenuItem>
                        )}
                      </Menu>
                    </Grid>
                  </Grid>
              </>
            ) : <EmptyList srcImage={emptyMessageIcon} text='Não há nenhuma denúncia.' />
        }

        <ModalDetail
          open={openModalDetail}
          setOpenModal={setOpenModalDetail}
          complaint={selectedComplaint}
          collaboratorName={collaboratorName(selectedComplaint)}
          resolveComplaint={resolveComplaint}
          answerComplaint={answerComplaint}
          isComplaintSolved={isComplaintSolved}
          isComplaintAnonymous={isComplaintAnonymous}
        />

      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={alertOptions.duration}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertOptions.severity}
          icon={alertOptions.icon}
          color={alertOptions.severity ? null : 'secondary_light'}
        >
          {alertOptions.snackbarText}
        </Alert>
      </Snackbar>
    </Container>
  );
}
