import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Pagination,
  TableRow,
} from '@mui/material';
import {
  Text,
  Loading,
  ImageHeader,
  EmptyList,
  ErrorState,
  ButtonComponent,
} from '../../components';
import { Input } from '../../components/Form';
import { FlexDirectionRowCenter, HeaderTableCell, TableRowStyled } from '../../styles/components';
import styles from './styles';
import GlobalStyles from '../../styles/GlobalStyles';
import { sectorIcon } from '../../assets/images/iconsQuickAction';
import { emptyMessageIcon } from '../../assets/images/icons';
import SectorsService from '../../services/napoleon/SectorsService';
import { ModalRegisterSector, ModalDeleteSector } from './components';

export default function Sectors() {
  const sectorsService = new SectorsService();
  const companyId = useSelector((state) => state.userReducer.decodeToken.user.companyId);
  const didMount = useRef(false);
  const didMountSubmitted = useRef(false);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [noSectors, setNoSectors] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const paginate = 10;

  const getSectors = (resetFilter) => {
    setNoSectors(true);
    setLoading(true);
    const search = resetFilter ? '' : searchText;

    sectorsService.getSectors(companyId, page, paginate, search)
      .then((response) => {
        const { data } = response;
        setSectors(data.sectors);
        setNoSectors(data.total === 0);
        setTotalPages(data.pages);
      })
      .catch((error) => {
        setHasError(true);
        throw error;
      })
      .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setSearchText('');
    setPage(1);
    getSectors(true);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !btnDisable) {
      getSectors();
    }
  };

  const handleOpenModalRegister = () => {
    setSelectedSector(null);
    setOpenModalRegister(true);
  };

  const handleClickUpdateSector = (item) => {
    setSelectedSector(item);
    setOpenModalRegister(true);
  };

  const handleClickDeleteSector = (item) => {
    setSelectedSector(item);
    setOpenModalDelete(true);
  };

  const loadTable = () => {
    if (noSectors) {
      return <EmptyList srcImage={emptyMessageIcon} text='Nenhum setor cadastrado.' />;
    }

    return (
      <>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <HeaderTableCell align="left">Nome</HeaderTableCell>
                <HeaderTableCell align="left">Criado</HeaderTableCell>
                <HeaderTableCell align="left">Ações</HeaderTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sectors.map((sector) => (
                <TableRowStyled key={sector.id} style={styles.tableRow}>
                  <TableCell align='left'>{sector.name}</TableCell>
                  <TableCell>{moment(sector.createdAt).format('DD/MM/YYYY')} - {moment(sector.createdAt).format('HH:mm')}</TableCell>
                  <TableCell align="left">
                    <ModeEditOutlineOutlinedIcon
                      onClick={() => handleClickUpdateSector(sector)}
                      fontSize='small'
                      color='secondary_light'
                      cursor='pointer'
                      sx={GlobalStyles.iconBorderThickness}
                    />
                    <DeleteOutlineOutlinedIcon
                      onClick={() => handleClickDeleteSector(sector)}
                      fontSize='small'
                      color='secondary_light'
                      cursor='pointer'
                      style={styles.deleteIcon}
                      sx={GlobalStyles.iconBorderThickness}
                    />
                  </TableCell>
                </TableRowStyled>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid item xs={12} style={styles.pagination}>
          <Pagination
            boundaryCount={1}
            siblingCount={1}
            page={page}
            onChange={handleChangePage}
            count={totalPages}
            color='primary'
            size='small'
          />
        </Grid>
      </>
    );
  };

  useEffect(() => {
    setBtnDisable(searchText.trim() === '');
  }, [searchText]);

  useEffect(() => {
    if (didMountSubmitted.current) {
      getSectors();
    } else {
      didMountSubmitted.current = true;
    }
  }, [submitted]);

  useEffect(() => {
    if (didMount.current) {
      if (page === 0) {
        setPage(1);
      } else {
        getSectors();
      }
    } else {
      didMount.current = true;
    }
  }, [page]);

  useEffect(() => getSectors(), []);

  if (hasError) return <ErrorState msg='Erro ao buscar setores. Tente novamente mais tarde.' />;

  if (loading) return <Loading />;

  return (
    <Container>
      <Grid container rowSpacing={1} columnSpacing={1} style={GlobalStyles.justifyContentRowCenter}>

        {/* Header */}
        <Grid item xs={12}>
          <Grid container style={GlobalStyles.justifyContentRowSpaceEvenly}>
            <Grid item xs={12} sm={8} style={GlobalStyles.justifyContentRowStart}>
              <FlexDirectionRowCenter>
                <ImageHeader src={sectorIcon} />
                <Text variant='h2'>Setores</Text>
              </FlexDirectionRowCenter>
            </Grid>

            <Grid item xs={12} sm={4} style={GlobalStyles.justifyContentRowFlexEnd}>
              <ButtonComponent onClick={handleOpenModalRegister} size="medium">
                Adicionar novo
              </ButtonComponent>
            </Grid>
          </Grid>
        </Grid>

        {/* Field to type search text */}
        <Grid
          item
          xs={12} sm={5}
          style={GlobalStyles.justifyContentRowStart}
          marginTop={1}
          marginRight={1}
        >
          <Input
            placeholder="Digite para pesquisar"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={handleKeyDown}
            style={styles.input}
            autoFocus={true}
            autoComplete="off"
            type="text"
          />
        </Grid>

        {/* Search/clean buttons */}
        <Grid
          container item
          xs={12} sm={6} lg={4}
          sx={styles.containerSearchButtons}
          columnSpacing={1}
        >
          <Grid item xs={4} style={GlobalStyles.justifyContentRowFlexEnd}>
            <ButtonComponent disabled={btnDisable} onClick={getSectors}>
              <SearchOutlinedIcon fontSize="small" /> Buscar&nbsp;
            </ButtonComponent>
          </Grid>

          <Grid item xs={4} style={GlobalStyles.justifyContentRowFlexEnd}>
            <ButtonComponent onClick={handleClear} variant='outlined'>
              Limpar
            </ButtonComponent>
          </Grid>
        </Grid>

        {loadTable()}

        <ModalRegisterSector
          open={openModalRegister}
          selectedSector={selectedSector}
          setSelectedSector={setSelectedSector}
          handleOpenModal={setOpenModalRegister}
          handleSubmitData={setSubmitted}
        />

        <ModalDeleteSector
          open={openModalDelete}
          selectedSector={selectedSector}
          setSelectedSector={setSelectedSector}
          handleOpenModal={setOpenModalDelete}
          handleSubmitData={setSubmitted}
        />
      </Grid>
    </Container>
  );
}
