import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

export const Img = styled('img')({
  maxWidth: '100%',
  verticalAlign: 'middle',
  display: 'inline-block',
  width: '200px',
});

export const styles = createStyles({
  container: {
    minHeight: '80vh',
    marginTop: '2.5vh',
  },
});
