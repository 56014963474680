import {
  FIND_COLLABORATORS,
} from '../types';

const INITIAL_STATE = {
  collaborators: [],
  rowsPerPage: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_COLLABORATORS:
      return { ...state, collaborators: action.collaborators, rowsPerPage: action.rowsPerPage };
    default:
      return state;
  }
};
