import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';
import COLORS from '../../styles/Colors';

// When color is: primary or secondary
export const DefaultContainer = styled('span')({
  borderRadius: 20,
  padding: '6px 10px',
});

export const styles = createStyles({
  primary: {
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.WHITE,
  },
  secondary: {
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.SECONDARY_LIGHT,
  },
  danger: {
    border: `1px solid ${COLORS.DANGER}`,
    color: COLORS.PRIMARY,
    backgroundColor: COLORS.DANGER,
    borderRadius: 20,
    padding: '4px 10px',
  },
});
