import { useHistory } from 'react-router-dom';
import { ButtonComponent, Text, Modal } from '../../../../components';
import { addUserIcon, addIcon, importSpreadsheetIcon } from '../../../../assets/images/icons';
import '../../../../styles/global.css';

export default function ModalAddCollaborator({ openModal, setOpenModal }) {
  const history = useHistory();

  const closeModal = () => setOpenModal(false);

  const handleImportFile = () => history.push('/admin/collaborators-import');

  const handleAddCollaborator = () => history.push('/admin/add-collaborator');

  return (
    <Modal open={openModal} onClose={closeModal} width={550}>
      <>
        <img src={addUserIcon} width={50} alt='Adicionar colaborador' />
        <Text variant="h2" marginTop={1} marginBottom={2}>Como você quer adicionar?</Text>

        <div className='flex-between'>
          <ButtonComponent color='primary_light' size='medium' onClick={handleAddCollaborator}>
            <Text variant='hintTextLabel'>
              <img src={addIcon} width={20} /> Individualmente
            </Text>
          </ButtonComponent>

          <ButtonComponent color='primary_light' size='medium' onClick={handleImportFile}>
            <Text variant='hintTextLabel'>
              <img src={importSpreadsheetIcon} width={20} /> Importar arquivo
            </Text>
          </ButtonComponent>
        </div>
      </>
    </Modal>
  );
}
