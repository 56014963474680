import { drawerWidth, transition, container } from '../../general';

const appStyle = (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    backgroundColor: '#eee',
    height: '100vh',
  },
  mainPanel: {
    overflow: 'auto',
    paddingBottom: '20px;',
    backgroundColor: '#fff',
    boxShadow: '0 1px 10px 0 rgb(0 0 0 / 10%)',
    ...transition,
    minHeight: '100%',
  },
  content: {
    marginTop: '70px',
    padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
  container,
  map: {
    marginTop: '70px',
  },
  containerSidebar: {
    width: `${drawerWidth}px`,
    maxWidth: `${drawerWidth}px`,
    padding: '15px 7.5px 15px 15px',
    backgroundColor: '#e6e8ee',
  },
  containerContent: {
    [theme.breakpoints.up('md')]: {
      width: `calc(99% - ${drawerWidth}px)`,
      maxWidth: `calc(99% - ${drawerWidth}px)`,
    },
  },
});

export default appStyle;
