/* eslint-disable no-useless-escape */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import LoginForm from '../../components/Login/LoginForm';
import AuthService from '../../services/napoleon/AuthService';
import {
  Container,
  ContainerMsg,
  Title,
  ContainerLoginForm,
  ErrorLogin,
  styles,
} from './styles';
import { Logo, Text } from '../../components';
import COLORS from '../../styles/Colors';

const errorMessages = {
  MANDATORY_FIELD: 'Campo obrigatório',
};

export default function Login() {
  const [Email, setEmail] = useState('');
  const [PassWord, setPassWord] = useState('');
  const loginDispatch = useDispatch();
  const tokenDispatch = useDispatch();
  const [emailLabelError, setEmailLabelError] = useState('');
  const [passWordLabelError, setPassWordLabelError] = useState('');
  const [emailNull, setEmailNull] = useState(false);
  const [passwordNull, setPasswordNull] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [sessionExpiredError, setSessionExpiredError] = useState(false);
  const sessionExpired = useSelector((state) => state.userReducer.sessionExpired);
  const authService = new AuthService();
  const [loading, setLoading] = useState(false);

  const access = () => {
    loginDispatch({ type: 'FIND_IS_AUTHENTICATED', isAuthenticated: false });
    setLoginError(false);
    setSessionExpiredError(false);

    const payload = {
      email: Email,
      password: PassWord,
    };

    authService
      .signIn(payload)
      .then((response) => {
        setLoading(false);
        const decoded = jwtDecode(response.data.token);
        tokenDispatch({ type: 'FIND_TOKEN', token: response.data.token });
        tokenDispatch({ type: 'FIND_TOKEN_DECODE', decodeToken: decoded });
        loginDispatch({ type: 'FIND_IS_AUTHENTICATED', isAuthenticated: response.data.auth });
      })
      .catch(() => {
        setLoading(false);
        setLoginError(true);
        setEmailNull(false);
        setPasswordNull(false);
      });
  };

  const resetValidation = () => {
    setLoginError(false);
    setSessionExpiredError(false);
    setEmailNull(false);
    setPasswordNull(false);
    setEmailLabelError('');
    setPassWordLabelError('');
  };

  const isValidEmail = (email) => {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(email);
  };

  const validateEmail = () => {
    if (Email === '') {
      setEmailNull(true);
      setEmailLabelError(errorMessages.MANDATORY_FIELD);
    }

    if (!isValidEmail(Email)) {
      setEmailNull(true);
      setPasswordNull(true);
    } else {
      setEmailNull(false);
      setEmailLabelError('');
    }
  };

  const validatePassword = () => {
    if (PassWord === '') {
      setPassWordLabelError(errorMessages.MANDATORY_FIELD);
      setPasswordNull(true);
    } else {
      setPasswordNull(false);
      setPassWordLabelError('');
    }
  };

  const validate = () => {
    if (loading) return;

    resetValidation();
    validateEmail();
    validatePassword();

    if (emailNull || passwordNull) return;

    setLoading(true);
    access();
  };

  const verifyEnterPress = (event) => {
    if (event.keyCode === 13) {
      validate();
    }
  };

  const renderMessageError = () => {
    if (loginError) return 'Dados incorretos';
    if (sessionExpiredError) return 'Sessão expirada, realize o login novamente';
    return '';
  };

  useEffect(() => {
    if (sessionExpired) {
      setSessionExpiredError(true);
      loginDispatch({ type: 'RESET_USER_STATE' });
    }
  }, [sessionExpired]);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={7} md={8} lg={8} container>
          <ContainerMsg>
            <Logo style={styles.logo} />
            <Title>Para distribuir benefícios, ou para comunicar com seus colaboradores.</Title>
            <Text variant='h3' style={styles.subTitle}>Nós estamos aqui para ajudar.</Text>
          </ContainerMsg>
        </Grid>

        <Grid item xs={12} sm={5} md={4} lg={4} container>
          <Grid item xs container direction="column" spacing={1}>
            <ContainerLoginForm>
              <LoginForm
                valueEmail={Email}
                onChangeEmail={(event) => setEmail(event.target.value)}
                onChangePass={(event) => setPassWord(event.target.value)}
                valuePass={PassWord}
                emailLabelError={emailLabelError}
                passWordLabelError={passWordLabelError}
                emailNull={emailNull}
                passwordNull={passwordNull}
                onClick={() => validate()}
                validateEmail={(event) => {
                  validateEmail();
                  verifyEnterPress(event);
                }}
                validatePassword={(event) => {
                  validatePassword();
                  verifyEnterPress(event);
                }}
                showLoading={loading}
              />
              {(loginError || sessionExpiredError)
                && (<ErrorLogin>
                  <Text variant='label' color={COLORS.SECONDARY_LIGHT}>{renderMessageError()}</Text>
                </ErrorLogin>)
              }
            </ContainerLoginForm>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
