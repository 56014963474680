import createStyles from '@mui/styles/createStyles';
import GlobalStyles from '../../../../styles/GlobalStyles';

const styles = createStyles({
  modal: {
    ...GlobalStyles.modal,
    width: 400,
  },
  input: {
    width: '100%',
  },
  marginTop: {
    marginTop: 20,
  },
});

export default styles;
