import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

export const DescriptionContainer = styled('div')({
  marginBottom: 10,
});

export const DescriptionIndicator = styled('span')({
  width: 15,
  height: 15,
  minHeight: 15,
  minWidth: 15,
  marginRight: 10,
  borderRadius: 999,
});

export const DescriptionHighlight = styled('span')({
  marginRight: 5,
  fontWeight: 'bold',
});

export const Img = styled('img')({
  height: 60,
  width: 60,
});

export const styles = createStyles({
  justifyContentRowStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  pageContainerNoCollaborators: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '10px',
  },
  bigChart: {
    width: 500,
    height: 250,
  },
  spacingTop: {
    marginTop: 20,
  },
  spacingBottom: {
    marginBottom: 10,
  },
});
