import React from 'react';
import {
  Modal,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import moment from 'moment';
import { Text, ButtonComponent, Tag } from '../../../../components';
import GlobalStyles from '../../../../styles/GlobalStyles';
import styles from './styles';

export default function ModalDetail(props) {
  const {
    open,
    setOpenModal,
    complaint,
    collaboratorName,
    resolveComplaint,
    answerComplaint,
    isComplaintSolved,
    isComplaintAnonymous,
  } = props;

  return (
    <Modal
      open={open}
      onClose={() => setOpenModal(false)}
      onBackdropClick={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={GlobalStyles.modal} style={styles.modal}>
        <Grid container spacing={1} style={GlobalStyles.justifyContentRowCenter}>
          {/* Header */}
          <Grid item xs={12}>
            <Grid container style={GlobalStyles.justifyContentRowSpaceEvenly}>
              {/* Path */}
              <Grid item xs={6} sm={9.5} style={GlobalStyles.justifyContentColumnStart}>
                <Text variant='h4'>Denúncias {'>'} Visualizar</Text>
              </Grid>

              {/* Status / Close */}
              <Grid item xs={6} sm={2.5} style={GlobalStyles.justifyContentRowFlexEndAlignCenter}>
                <Tag color={complaint.status?.color}>{complaint.status?.name}</Tag>
                <IconButton onClick={() => setOpenModal(false)}>
                  <CloseOutlinedIcon color='secondary' />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {/* Body */}
          <Grid item container xs={12}>
            <Grid item xs={12} sm={10}>
              <Grid item container xs={12}>
                <Text variant='h3' color='secondary'>Enviado por: {collaboratorName}</Text>
              </Grid>

              <Grid item container xs={12} style={GlobalStyles.smallMarginTop} spacing={1}>
                <Grid item xs={6} sm={3} style={GlobalStyles.justifyContentColumnStart}>
                  <Text variant='h4' color='secondary'>Data e horário</Text>
                  <Text variant='hintText'>
                    {moment(complaint.createdAt).format('DD/MM/YYYY')} às {moment(complaint.createdAt).format('HH:mm')}
                  </Text>
                </Grid>

                <Grid item xs={6} sm={3} style={GlobalStyles.justifyContentColumnStart}>
                  <Text variant='h4' color='secondary'>Assunto</Text>
                  <Text variant='hintText'>{complaint.subject}</Text>
                </Grid>

                <Grid item xs={12} style={GlobalStyles.justifyContentColumnStart}>
                  <Grid variant='paragraph' style={styles.messageDescription}>{complaint.message}</Grid>
                </Grid>

                <Grid
                  container
                  rowSpacing={1}
                  marginTop={1}
                  style={GlobalStyles.justifyContentRowStart}
                >
                  { !isComplaintSolved() && !isComplaintAnonymous() && (
                    <Grid item xs={12} sm={2} md={2} lg={1.5}>
                      <ButtonComponent
                        size='small'
                        onClick={() => answerComplaint(complaint.collaborator)}
                        variant='outlined'
                      >
                        Responder
                      </ButtonComponent>
                    </Grid>
                  )}

                  { !isComplaintSolved() && (
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <ButtonComponent
                        size='medium'
                        onClick={() => { resolveComplaint(complaint.id); setOpenModal(false); }}
                        variant='outlined'
                      >
                        Marcar como resolvido
                      </ButtonComponent>
                    </Grid>
                  )}

                  { !isComplaintSolved() && !isComplaintAnonymous() && (
                    <Grid item xs={12} sm={5} md={5} lg={4}>
                      <ButtonComponent
                        size='large'
                        onClick={() => answerComplaint(complaint.collaborator, true)}
                        variant='outlined'
                      >
                        Responder e marcar como resolvido
                      </ButtonComponent>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
