import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';

import { COLORS, theme } from '../../../../styles';

export const Forms = styled(Grid)`
  display: flex;
  width: 100%;
  margin: 40px 0 30px;
  padding: 0 15px 40px 15px;
  border-bottom: ${(props) => (props.last ? '0' : `1px solid ${COLORS.SECONDARY_LIGHT}`)};
`;

export const Select = styled.select`
  display: block;
  width: 100%;
  height: 38px;
  margin-top: 5px;
  padding: 8px 12px;
  text-transform: none;
  font-size: ${theme.typography.hintText.fontSize}px;
  background-color: ${COLORS.LIGHT};
  color: ${COLORS.SECONDARY_LIGHT};
  border: 1px solid ${COLORS.PRIMARY_LIGHT};
`;

const styles = createStyles({
  flex: {
    display: 'flex',
  },
  none: {
    display: 'none',
  },
});

export default styles;
