import { SHOW_TOAST, HIDE_TOAST } from '../types';

const initialState = {
  open: false,
  type: 'success',
  text: '',
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        open: true,
        type: action.payload.type,
        text: action.payload.text,
      };
    case HIDE_TOAST:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

const showSuccessToast = (text) => ({
  type: SHOW_TOAST,
  payload: { type: 'success', text },
});

const showErrorToast = (text) => ({
  type: SHOW_TOAST,
  payload: { type: 'error', text },
});

const hideToast = () => ({ type: HIDE_TOAST });

export default toastReducer;

export { showSuccessToast, showErrorToast, hideToast };
