import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
  link: {
    textDecoration: 'underline',
    fontWeight: '600',
    marginLeft: 5,
  },
});

export default styles;
