import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

const Img = styled('img')({
  height: 35,
  width: 35,
});

const ComingSoon = styled('div')({
  position: 'absolute',
  top: 10,
  right: 10,
});

const styles = createStyles({
  text: {
    paddingTop: 2,
    marginBottom: 1,
  },
});

export default styles;

export { Img, ComingSoon };
