import React from 'react';
import Grid from '@mui/material/Grid';
import { Img, styles } from './styles';
import Text from '../Text';

export default function EmptyList({ srcImage, text }) {
  return (
    <Grid item xs={12} style={styles.grid} container>
      <Img src={srcImage} />
      <Text variant='hintText'>{ text }</Text>
    </Grid>
  );
}
