import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  primaryDarkblue,
  grayColor,
  blackColor,
  hexToRgb,
} from '../../general';
import COLORS from '../../../../styles/Colors';

const sidebarStyle = (theme) => ({
  drawerPaper: {
    height: '100%',
    zIndex: '1',
    ...boxShadow,
    width: drawerWidth,
    maxWidth: drawerWidth,
    borderRight: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  drawerPaperRTL: {
    [theme.breakpoints.up('md')]: {
      left: 'auto !important',
      right: '0 !important',
    },
    [theme.breakpoints.down('md')]: {
      left: '0  !important',
      right: 'auto !important',
    },
  },
  logo: {
    position: 'relative',
    padding: '15px 15px',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: `rgba(${hexToRgb(grayColor[6])}, 0.3)`,
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor,
    },
  },
  logoLinkRTL: {
    textAlign: 'right',
  },
  logoImage: {
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  img: {
    width: '35px',
    top: '22px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    flex: 1,
    height: '100%',
  },
  item: {
    position: 'relative',
    display: 'block',
    margin: '5px 0px 0',
    textDecoration: 'none',
    opacity: 0.4,
    '&.active': {
      opacity: 1,
    },
    '&:hover,&:focus,&:visited,&': {
      color: '#515F86',
      textDecoration: 'none',
      '& > *:first-child': {
        opacity: '1',
      },
    },
  },
  itemUser: {
    display: 'flex',
    width: '100%',
    alignItens: 'center',
  },
  itemLink: {
    width: '90%',
    transition: 'all 300ms linear',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    backgroundColor: 'transparent',
    ...defaultFont,
  },
  userItem: {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'flex',
    padding: '10px 15px',
    backgroundColor: 'transparent',
    ...defaultFont,
  },
  itemIcon: {
    width: '27px',
    height: '30px',
    fontSize: '2px',
    lineHeight: '30px',
    float: 'left',
    marginRight: '12px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: `rgba(${hexToRgb('#9e9e9e')}, 0.8)`,
  },
  itemIconUser: {
    width: '24px',
    height: '30px',
    fontSize: '24px',
    lineHeight: '30px',
    float: 'left',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: `rgba(${hexToRgb('#071a52')}, 0.8)`,
  },
  iconUser: {
    color: '#9e9e9e',
    marginRight: '-18px',
  },
  itemIconRTL: {
    marginRight: '3px',
    marginLeft: '15px',
    float: 'right',
  },
  itemText: {
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    color: '#071a52',
    fontWeight: '500',
  },
  itemTextUser: {
    ...defaultFont,
    margin: '0',
    color: '#071a52',
    fontWeight: '500',
    textDecoration: 'none',
  },
  Textexit: {
    fontSize: '9px',
    fontWeight: '600',
    marginLeft: '8%',
    lineHeight: '0px',
    color: '#071a52',
  },
  itemTextRTL: {
    textAlign: 'right',
  },
  whiteFont: {
    color: whiteColor,
  },

  purple: {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
    '&:hover,&:focus': {
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },

  blue: {
    backgroundColor: infoColor[0],
    boxShadow:
      `0 12px 20px -10px rgba(${hexToRgb(infoColor[0])},.28),`
      + ` 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12),`
      + `  0 7px 8px -5px rgba(${hexToRgb(infoColor[0])},.2)`,
    '&:hover,&:focus': {
      backgroundColor: infoColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        infoColor[0],
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
        infoColor[0],
      )},.2)`,
    },
  },
  green: {
    backgroundColor: successColor[0],
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
      successColor[0],
    )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
      successColor[0],
    )},.2)`,
    '&:hover,&:focus': {
      backgroundColor: successColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        successColor[0],
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
        successColor[0],
      )},.2)`,
    },
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
      warningColor[0],
    )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
      warningColor[0],
    )},.2)`,
    '&:hover,&:focus': {
      backgroundColor: warningColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        warningColor[0],
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
        warningColor[0],
      )},.2)`,
    },
  },
  red: {
    backgroundColor: dangerColor[0],
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
      dangerColor[0],
    )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
      dangerColor[0],
    )},.2)`,
    '&:hover,&:focus': {
      backgroundColor: dangerColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        dangerColor[0],
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
        dangerColor[0],
      )},.2)`,
    },
  },
  primarydarkblue: {
    color: primaryDarkblue,
    backgroundColor: '#071A52',
    boxShadow: `0 12px 20px -10px rgba(${hexToRgb('#071A52')},.28), 0 4px 20px 0 rgba(${hexToRgb(
      blackColor,
    )},.12), 0 7px 8px -5px rgba(${hexToRgb('#071A52')},.2)`,
    '&:hover,&:focus': {
      backgroundColor: '#071A52',
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb('#071A52')},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor,
      )},.12), 0 7px 8px -5px rgba(${hexToRgb('#071A52')},.2)`,
    },
  },
  lightblue: {
    color: primaryDarkblue,
    backgroundColor: '#FFF',
    boxShadow: `0 0px 0px 0px rgba(${hexToRgb('#FFF')},.28), 0 0px 0px 0 rgba(${hexToRgb(
      blackColor,
    )},.12), 0 0px 0px 0px rgba(${hexToRgb('#FFF')},.2)`,
    '&:hover,&:focus': {
      backgroundColor: '#FFF',
      boxShadow: `0 0px 0px 0px rgba(${hexToRgb('#FFF')},.28), 0 0px 0px 0 rgba(${hexToRgb(
        blackColor,
      )},.12), 0 0px 0px 0px rgba(${hexToRgb('#FFF')},.2)`,
    },
  },

  sidebarWrapper: {
    position: 'relative',
    height: '100%',
    backgroundColor: COLORS.PRIMARY,
    boxShadow: '0 2px 10px 0 rgb(0 0 0 / 10%)',
    borderRight: '1px solid #f5e6f2',
  },

  activePro: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: '13px',
    },
  },
});

export default sidebarStyle;
