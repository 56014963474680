import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Modal, CircularProgress } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import { ButtonsContainer, styles } from './styles';
import { iconErrorIcon } from '../../../../assets/images/icons';
import CollaboratorService from '../../../../services/napoleon/CollaboratorService';
import { Text, ButtonComponent } from '../../../../components';
import GlobalStyles from '../../../../styles/GlobalStyles';
import { showSuccessToast, showErrorToast } from '../../../../redux/reducers/toastReducer';

function InactiveCollaboratorModal({
  collaboratorToBeInactivated,
  clearCollaboratorToBeInactivated,
  removeCollaboratorInactivateFromList,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [chosenCollaborator, setChosenCollaborator] = useState({
    id: null,
    name: null,
    cpf: null,
  });
  const [collaboratorWasInactivated, setCollaboratorWasInactivated] = useState(null);
  const [loading, setLoading] = useState(false);
  const collaboratorService = new CollaboratorService();
  const dispatch = useDispatch();

  const resetModal = () => {
    clearCollaboratorToBeInactivated(null);
    setCollaboratorWasInactivated(null);
    setOpenModal(false);
    setChosenCollaborator({
      id: null,
      name: null,
      cpf: null,
    });
  };

  const handleCloseModal = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }

    if (reason === 'escapeKeyDown') {
      return false;
    }

    if (loading) {
      return false;
    }

    return resetModal();
  };

  const extractInformationFromCollaborator = (fieldName) => {
    const { informations } = collaboratorToBeInactivated;
    const [{ value }] = informations.filter((information) => information.name === fieldName);
    return value;
  };

  /**
   * The name field can be empty, when the name is empty the backend returns the "-" character,
   * so if there is no name cpf is displayed
   * @returns {String} collaborator identification (name or cpf)
   */
  const renderCollaboratorIdentification = () => {
    if (chosenCollaborator.name === '-') {
      return chosenCollaborator.cpf;
    }

    return chosenCollaborator.name;
  };

  /**
   * The collaboratorWasInactivated field controls 3 possible states:
   * When 'null': modal content is the question to confirm deletion;
   * When 'true': the content of the modal is the collaborator message inactivated successfully;
   * When 'false: the content of the modal is the collaborator not inactivated message
   * because there was some error inactivating.
  */
  const inactivateCollaborator = () => {
    setLoading(true);

    collaboratorService
      .inactivateCollaborator(chosenCollaborator.id)
      .then(({ data }) => {
        setCollaboratorWasInactivated(true);
        removeCollaboratorInactivateFromList(data);
        dispatch(showSuccessToast('Colaborador(a) excluído(a) com sucesso!'));
        resetModal();
      })
      .catch(() => {
        setCollaboratorWasInactivated(false);
        dispatch(showErrorToast('Erro ao excluir colaborador(a). Tente novamente mais tarde.'));
      })
      .finally(() => setLoading(false));
  };

  /**
   * The component always listens if a new contributor has been selected to activate the modal
   */
  useEffect(() => {
    if (collaboratorToBeInactivated) {
      setOpenModal(true);
      setChosenCollaborator({
        id: collaboratorToBeInactivated.id,
        name: extractInformationFromCollaborator('Nome Completo'),
        cpf: collaboratorToBeInactivated.cpf,
      });
    } else {
      setCollaboratorWasInactivated(null);
      setOpenModal(false);
      setChosenCollaborator({
        id: null,
        name: null,
        cpf: null,
      });
    }
  }, [collaboratorToBeInactivated]);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      onBackdropClick={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modal} textAlign='center'>
        {
          collaboratorWasInactivated === null ? (
            <>
              <WarningAmberRoundedIcon color='primary' style={styles.warningIcon} />
              <Text variant="h2">Atenção</Text>
              <Text variant='paragraph' marginTop={1} marginBottom={2}>
                Deseja excluir o(a) colaborador(a) <br/>
                <b>{renderCollaboratorIdentification()}</b>?
              </Text>

              <ButtonsContainer>
                <ButtonComponent onClick={handleCloseModal} color='primary_light' disabled={loading}>
                  Fechar
                </ButtonComponent>
                <ButtonComponent onClick={inactivateCollaborator} disabled={loading}>
                  {loading ? <CircularProgress size={21} /> : 'Sim'}
                </ButtonComponent>
              </ButtonsContainer>
            </>
          ) : (
            <div style={GlobalStyles.justifyContentColumnCenter}>
              <img style={styles.image} src={iconErrorIcon} />

              <Text variant="h2">Falha ao excluir colaborador</Text>
              <Text variant='paragraph' marginTop={1} marginBottom={2}>
                Tente novamente mais tarde.
              </Text>

              <ButtonComponent onClick={resetModal} color='primary_light'>
                Fechar
              </ButtonComponent>
            </div>
          )
        }
      </Box>
    </Modal>
  );
}

export default InactiveCollaboratorModal;
