import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { BootstrapButton, styles } from './styles';

/**
 * @param {{
 *  size: 'small' | 'medium' | 'large',
 *  color: 'primary' | 'primary_light' | 'secondary' | 'secondary_light' | 'danger' | 'light',
 *  variant: 'text' | 'contained' | 'outlined'
 * }} props
*/

export default function ButtonComponent({
  color,
  size,
  variant,
  loading,
  disabled,
  onClick,
  className,
  sx,
  style,
  rounded,
  children,
}) {
  function getButtonSize() {
    if (size === 'large') return styles.largeButton;
    if (size === 'medium') return styles.mediumButton;
    return styles.smallButton;
  }
  const sizeButton = getButtonSize();
  const stateButton = disabled && styles.disabledButton;

  return (
    <BootstrapButton
      color={color}
      disableRipple={disabled}
      variant={variant}
      className={clsx(className)}
      sx={[sizeButton, stateButton, sx]}
      style={style}
      rounded={rounded ? 1 : 0}
      onClick={disabled || loading ? null : onClick}
    >
      {loading ? <CircularProgress size={20} color='inherit' /> : children}
    </BootstrapButton>
  );
}

ButtonComponent.defaultProps = {
  color: 'primary',
  size: 'small',
  variant: 'contained',
  loading: false,
  disabled: false,
  rounded: true,
};

ButtonComponent.propTypes = {
  color: PropTypes.oneOf(['primary', 'primary_light', 'secondary', 'secondary_light', 'danger', 'light']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  style: PropTypes.object,
  children: PropTypes.node,
};
