// USE onAccept TO HANDLE CHANGES WITHIN MASKED FIELDS (docs: https://www.npmjs.com/package/react-imask)
import { IMaskMixin } from 'react-imask';
import StyledInput, { useStyles, styles } from './styles';

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => {
  const classes = useStyles(props);
  const { mask, unmask, ...rest } = props;

  return (
    <StyledInput
      {...rest}
      inputRef={inputRef}
      className={classes.styles}
      FormHelperTextProps={{ style: styles.helperText }}
    />
  );
});

const Input = (props) => <MaskedInput {...props} variant="standard" />;

export default Input;
