import api from './Api';
import getAuthToken from './helpers';

class SectorsService {
  constructor() {
    this.api = api;
  }

  create(payload) {
    return this.api.post('/sectors', payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  update(sector) {
    return this.api.put(`/sectors/${sector.id}/update`, sector, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  inactiveSector(sectorId) {
    return this.api.put(`/sectors/${sectorId}/inactive-sector`, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  getSectors(companyId, page = 1, paginate = 10, search) {
    let url = `/sectors/${companyId}/get-sectors?page=${page}&paginate=${paginate}`;

    if (search) {
      url += `&search=${search}`;
    }

    return this.api.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default SectorsService;
