import styled from '@mui/system/styled';
import Button from '@mui/material/Button';
import createStyles from '@mui/styles/createStyles';
import COLORS from '../../styles/Colors';

export const BootstrapButton = styled(Button)((props) => ({
  height: '40px',
  justifyContent: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  textAlign: 'center',
  textTransform: 'none',
  shadowOpacity: 0,
  borderRadius: props.rounded ? 25 : 3,
  lineHeight: '1.2',
}));

export const styles = createStyles({
  // ***************************************************
  // STYLES ABOUT THE SIZE OF THE BUTTON
  // ***************************************************
  smallButton: {
    width: 113,
  },
  mediumButton: {
    width: 225,
  },
  largeButton: {
    width: '100%',
  },
  // ***************************************************
  // STYLES ABOUT THE STATE OF THE BUTTON
  // ***************************************************
  disabledButton: {
    color: COLORS.PRIMARY,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    boxShadow: 'none',
    '&:hover': {
      color: COLORS.PRIMARY,
      backgroundColor: COLORS.PRIMARY_LIGHT,
      boxShadow: 'none',
    },
    '&:active': {
      color: COLORS.PRIMARY,
      backgroundColor: COLORS.PRIMARY_LIGHT,
      boxShadow: 'none',
    },
  },
});
