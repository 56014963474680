/* eslint-disable camelcase */
import api from './Api';
import getAuthToken from './helpers';

class StatusRequestRH {
  constructor() {
    this.api = api;
  }

  // Copy of the table 'status_request_rh'
  status = {
    open: { id: 1, name: 'Em aberto', color: 'primary' },
    solved: { id: 2, name: 'Resolvida', color: 'secondary' },
  }

  getStatus() {
    return this.api.get('/status-request-rh/get-status', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default StatusRequestRH;
