import styled from '@mui/system/styled';
import COLORS from '../../styles/Colors';

export const StyledCard = styled('div')((props) => ({
  width: '100%',
  height: 180,
  backgroundColor: props.disabled ? COLORS.LIGHT : COLORS.WHITE,
  paddingLeft: 15,
  paddingRight: 10,
  marginRight: 20,
  boxShadow: '0 2px 10px 0 rgb(0 0 0 / 10%)',
  borderWidth: 10,
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'start',
  alignItems: 'start',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: COLORS.LIGHT,
  },
  ...props.style,
}));
