import api from './Api';

class AuthService {
  constructor() {
    this.api = api;
  }

  signIn(payload) {
    return this.api.post('/auth/admin/sign_in', payload);
  }
}

export default AuthService;
