import {
  FIND_FORM_ID,
  HANDLER_COLLABORATORS_FILE_ERROR,
  FIND_FORM_DATA,
  HANDLER_LIST_PREVIEW,
  HANDLER_FILE,
  HANDLER_SELECTED_FIELDS,
  FIND_FORM,
  FIND_IMPORT_ERRORS,
} from '../types';

const INITIAL_STATE = {
  id: null,
  file: {
    fileName: '',
    headers: [],
    collaborators: [],
  },
  handlerError: {
    hasError: false,
    message: '',
  },
  collaboratorsFormData: {
    collaboratorsFile: {},
    selectedFields: {},
  },
  listPreview: [],
  file_collaborators: [],
  selectedFields: [],
  formElement: [],
  importValidateErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_FORM_ID:
      return { ...state, id: action.id };
    case HANDLER_COLLABORATORS_FILE_ERROR:
      return { ...state, handlerError: action.handlerError };
    case FIND_FORM_DATA:
      return { ...state, collaboratorsFormData: action.collaboratorsFormData };
    case HANDLER_LIST_PREVIEW:
      return { ...state, listPreview: action.listPreview };
    case HANDLER_FILE:
      return { ...state, file_collaborators: action.file_collaborators };
    case HANDLER_SELECTED_FIELDS:
      return { ...state, selectedFields: action.selectedFields };
    case FIND_FORM:
      return { ...state, formElement: action.formElement };
    case FIND_IMPORT_ERRORS:
      return { ...state, importValidateErrors: action.importValidateErrors };
    default:
      return state;
  }
};
