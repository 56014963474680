/* eslint-disable no-alert */
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import styles, {
  DescriptionContainer,
  DescriptionIconContainer,
  DescriptionIcon,
  DescriptionDivider,
} from './styles';
import { errorIcon, warningIcon } from '../../../../../../assets/images/icons';
import { ContainerFullPage } from '../../../../../../styles/components';
import GlobalStyles from '../../../../../../styles/GlobalStyles';
import { ButtonComponent, Loading, Text } from '../../../../../../components';
import ErrorImportFileMessage from '../ErrorImportFileMessage';
import ERRORS_VALIDATIONS from '../../../../../../constants/validationsImportCollaborator';
import { isEmpty } from '../../../../../../utils';
import useValidateCollaboratorsFile from '../../../../../../common/hooks/useValidateCollaboratorsFile';

export default function ReportErrorFile() {
  const history = useHistory();
  const listPreview = useSelector((state) => state.formReducer.listPreview);
  const selectedFields = useSelector((state) => state.formReducer.selectedFields);
  const {
    mergedFields: reportError,
    warning: allWarnings,
    error: allErrors,
  } = useSelector((state) => state.formReducer.importValidateErrors);
  const { validateColumnsCollaboratorsFile, loading } = useValidateCollaboratorsFile();

  const renderValidationType = (field, error, validationType) => {
    const fieldsToValidate = error[validationType];
    const validationError = ERRORS_VALIDATIONS[validationType]({ error: fieldsToValidate, field });
    const title = validationError?.title;
    const message = validationError?.message;

    // Skips this verification because if the error isn't in allWarnings array, it is in allErrors array
    // const foundError = !isEmpty(allErrors) && allErrors[field] && allErrors[field][validationType];
    const foundWarning = !isEmpty(allWarnings) && allWarnings[field] && allWarnings[field][validationType];

    if (!title) return <></>;

    return (
      <>
        <DescriptionContainer>
          <DescriptionIconContainer>
            <DescriptionIcon src={foundWarning ? warningIcon : errorIcon} />
          </DescriptionIconContainer>

          <Text variant='hintText'>{title}</Text>
        </DescriptionContainer>

        { message && (
          <Grid item xs={12}>
            <Text variant='h4'>{message}</Text>
            {
              fieldsToValidate.map((item, key) => (
                <Text key={key} variant='hintText' marginTop={1} noWrap={true}>
                  {typeof item === 'object' ? item.value : item}
                </Text>
              ))
            }
          </Grid>
        )}
      </>
    );
  };

  const renderFieldValues = (field) => {
    const fieldValues = [];
    selectedFields.map((prop) => listPreview
      .filter((item) => item.field === prop.fieldColumnName && prop.formFieldName === field)
      .map((e) => fieldValues.push(e.value)));
    return fieldValues;
  };

  const handleIgnoreWarnings = () => validateColumnsCollaboratorsFile(selectedFields, true);

  if (!reportError) return <ErrorImportFileMessage />;

  if (loading) return <Loading title='Processando…' />;

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>Encontramos alguns erros no arquivo.</Text>
      <Text variant='hintText' lineHeight={2}>
        Existem alguns erros no seu arquivo que precisam ser corrigidos.
      </Text>

      <Grid item xs={12} marginTop={3} marginBottom={2}>
        <Grid item xs={12} style={styles.descriptionContainer}>
          <Text variant='h4'>Legenda</Text>

          <DescriptionContainer>
            <DescriptionIconContainer>
              <DescriptionIcon src={warningIcon} />
            </DescriptionIconContainer>
            <Text variant='hintText'>
              Indicações em amarelo não impedem o carregamento do arquivo mas é recomendável que o
              máximo de informações estejam preenchidas para melhor funcionamento do sistema.
            </Text>
          </DescriptionContainer>

          <DescriptionContainer>
            <DescriptionIconContainer>
              <DescriptionIcon src={errorIcon} />
            </DescriptionIconContainer>
            <Text variant='hintText'>
              Indicações em vermelho impedem o carregamento do arquivo.
            </Text>
          </DescriptionContainer>
        </Grid>

        <Grid item xs={12} marginTop={5}>
          {Object.keys(reportError).map((field, keyField) => (
            <Grid item xs={12} key={keyField} marginTop={3}>
              <Text variant='h4'>{field}</Text>
              {renderFieldValues(field).map((name, keyName) => (
                <Text variant='hintText'
                  key={keyName}
                  style={GlobalStyles.smallMarginTop}
                >
                  {name}
                </Text>
              ))}

              <DescriptionDivider />

              {
                Object.keys(reportError[field]).map((validationType, keyValidationType) => (
                  <Fragment key={keyValidationType}>
                    {renderValidationType(field, reportError[field], validationType)}
                  </Fragment>
                ))
              }
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid container justifyContent='space-evenly' columnGap={0} rowGap={2} alignItems='center' marginTop={5}>
        { isEmpty(allErrors) ? (
          <ButtonComponent
            size='medium'
            variant="outlined"
            onClick={handleIgnoreWarnings}
          >
            Inserir mesmo assim
          </ButtonComponent>
        ) : null }

        <ButtonComponent size='medium' onClick={() => history.push('/admin/map-collaborators')}>
          Corrigir
        </ButtonComponent>
      </Grid>
    </ContainerFullPage>
  );
}
