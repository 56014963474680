import { useState } from 'react';
import { Grid } from '@mui/material';

import { emptyUsersIcon } from '../../../../assets/images/icons';
import styles, { Img } from './styles';
import { ButtonComponent, Text } from '../../../../components';
import ModalAddCollaborator from '../ModalAddCollaborator';

export default function EmptyList() {
  const [showModalAddCollaborator, setShowModalAddCollaborator] = useState(false);
  const handleClickAddCollaborator = () => setShowModalAddCollaborator(true);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={styles.container}
    >
      <Grid item xs={12}>
        <Img src={emptyUsersIcon} />
      </Grid>

      <Grid item xs={12}>
        <Text variant="h3" textAlign="center">
          Não há nenhum colaborador na sua lista.
        </Text>
        <Text variant="hintText" textAlign="center">
          Comece adicionando colaboradores.
        </Text>
      </Grid>

      <Grid item xs={12} style={styles.containerButton}>
        <ButtonComponent size="medium" onClick={handleClickAddCollaborator}>
          Adicionar Colaboradores
        </ButtonComponent>
      </Grid>

      <ModalAddCollaborator
        openModal={showModalAddCollaborator}
        setOpenModal={setShowModalAddCollaborator}
      />
    </Grid>
  );
}
