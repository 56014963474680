import api from './Api';
import getAuthToken from './helpers';

class FormService {
  constructor() {
    this.api = api;
  }

  analyseColumnsCollaboratorsFile(formId, formData) {
    return this.api.post(`/forms/${formId}/collaborators-file/read`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  validateColumnsCollaboratorsFile(formId, formData) {
    return this.api.post(`/forms/${formId}/collaborators-file/validate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default FormService;
