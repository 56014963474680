import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';

import { ButtonComponent, Card, Text } from '../../../../components';
import useCollaborator, { noValue } from '../../../../common/hooks/useCollaborator';
import { DATA_DE_NASCIMENTO, NOME_COMPLETO, SETOR } from '../../../../constants/formElementNames';
import COLORS from '../../../../styles/Colors';
import styles from './styles';

moment.locale('pt-br');

export default function BirthdayCollaborator({ collaborator }) {
  const history = useHistory();
  const { getInfo } = useCollaborator();
  const sectorName = getInfo(collaborator, SETOR);
  const collaboratorName = getInfo(collaborator, NOME_COMPLETO);

  function formatDate(inputDate) {
    const today = moment();
    const birthday = moment(inputDate, 'YYYY-MM-DD');
    const birthdayThisYear = birthday.year(today.year());
    const diffDays = birthdayThisYear.diff(today, 'days');

    if (diffDays === -3) return 'Três dias atrás';
    if (diffDays === -2) return 'Dois dias atrás';
    if (diffDays === -1) return 'Ontem';
    if (diffDays === 0) return 'Hoje';
    if (diffDays === 1) return 'Amanhã';

    return birthdayThisYear.format('D [de] MMMM');
  }

  function getBirthday() {
    const birthdayDate = getInfo(collaborator, DATA_DE_NASCIMENTO);
    const convertedDate = moment(birthdayDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    return formatDate(convertedDate);
  }

  const collaboratorBirthday = getBirthday();

  function getAvatarUrl() {
    const encodedName = encodeURIComponent(collaboratorName);
    const formatColor = (color) => color.substring(1);
    const birthdayIsToday = collaboratorBirthday === 'Hoje';
    const background = formatColor(birthdayIsToday ? COLORS.PRIMARY : COLORS.PRIMARY_LIGHT);
    const color = formatColor(birthdayIsToday ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY);

    return `https://ui-avatars.com/api/?name=${encodedName}&background=${background}&color=${color}&size=128&rounded=true`;
  }

  const handleSendMessage = () => (
    history.push('/admin/create-message', {
      path: 'directMessage',
      collaborator: {
        id: collaborator.id,
        name: collaboratorName,
      },
    })
  );

  return (
    <Card sx={styles.card}>
      <img
        src={getAvatarUrl()}
        alt={`Avatar do colaborador ${collaboratorName}`}
        width={50}
      />

      <Text variant='h4' paddingTop={2} sx={styles.collaboratorName}>{collaboratorName}</Text>

      {sectorName !== noValue && <Text variant='hintText'>{sectorName}</Text>}

      <Box width='85%' paddingTop={1}>
        <ButtonComponent size="large" onClick={handleSendMessage}>
          Enviar Mensagem
        </ButtonComponent>
      </Box>

      <Box paddingTop={2}>
        <Text variant='label'>{collaboratorBirthday}</Text>
      </Box>
    </Card>
  );
}
