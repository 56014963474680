import { FIND_REQUESTS, RESOLVE_REQUEST } from '../types';

const INITIAL_STATE = {
  newRequests: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_REQUESTS:
      return { ...state, newRequests: action.newRequests };
    case RESOLVE_REQUEST:
      return { ...state, newRequests: Math.max(state.newRequests - 1, 0) };
    default:
      return state;
  }
};
