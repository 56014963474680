import React from 'react';
import MuiAlert from '@mui/material/Alert';
import styles from './styles';

// eslint-disable-next-line no-shadow
const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" sx={styles.alert} {...props} />
));

export default Alert;
