import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';
import COLORS from '../../styles/Colors';
import theme from '../../styles/theme';

export const drawerWidth = 250;

export const DrawerHeader = styled('div')({
  width: '100%',
  marginBottom: 20,
  padding: '20px 10px 20px 20px',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const localStyles = createStyles({
  appBar: {
    width: { md: `calc(100% - ${drawerWidth}px)` },
    ml: { md: `${drawerWidth}px` },
    display: { md: 'none' },
    backgroundColor: COLORS.PRIMARY_LIGHT,
    marginBottom: 100,
  },
  iconButton: {
    mr: 2,
    display: { md: 'none' },
  },
  mobileDrawer: {
    display: {
      sm: 'block',
      md: 'none',
    },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  },
  webDrawer: {
    display: {
      xs: 'none',
      md: 'block',
    },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  },
  navLink: {
    textDecoration: 'none',
    height: 50,
  },
  listItem: {
    backgroundColor: COLORS.PRIMARY,
    borderRadius: 40,
    width: drawerWidth - 20,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    left: 5,
    '&:hover': {
      backgroundColor: COLORS.SELECTION_STATUS,
    },
  },
  activeListItem: {
    backgroundColor: COLORS.SELECTION_STATUS,
    borderRadius: 25,
  },
  listItemText: {
    fontSize: 15,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  userName: {
    marginBottom: 1,
    color: COLORS.PRIMARY_LIGHT,
  },
  drawerFooter: {
    marginTop: 'auto',
  },
  notificationContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  notificationText: {
    display: 'flex',
    backgroundColor: COLORS.DANGER,
    color: COLORS.PRIMARY,
    borderRadius: '100%',
    marginLeft: 8,
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.label,
  },
});
