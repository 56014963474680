import styled from 'styled-components';
import createStyles from '@mui/styles/createStyles';

export const ContainerRemember = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const styles = createStyles({
  marginBottom: {
    marginBottom: 2,
  },
  textField: {
    width: '85%',
    marginTop: 2,
    marginBottom: 1,
  },
});
