import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';

import { TEMPLATE_SPREADSHEET_IMPORTING_EMPLOYEES } from '../../../../../../constants/links';
import FormService from '../../../../../../services/napoleon/FormService';
import {
  Text,
  ButtonComponent,
  Link,
  Loading,
} from '../../../../../../components';
import { ContainerFullPage } from '../../../../../../styles/components';
import styles from './styles';
import GlobalStyles from '../../../../../../styles/GlobalStyles';
import { importSpreadsheetIcon } from '../../../../../../assets/images/icons';

export default function Instructions() {
  let hasFileError = false;
  const formId = useSelector((state) => state.formReducer.id);
  const collaboratorsFileErrorDispatch = useDispatch();
  const formDataDispatch = useDispatch();
  const history = useHistory();
  const fileDispatch = useDispatch();
  const clearFieldSelected = useDispatch();
  const formService = new FormService();
  const formFields = useSelector((state) => state.formReducer.formElement.filter((field) => field.active));
  const requiredFormFields = formFields.filter((field) => field.required);
  const optionalFormFields = formFields.filter((field) => !field.required);
  const fileInput = useRef();
  const [selectedFileName, setSelectedFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const analyseColumnsCollaboratorsFile = (formData) => {
    setLoading(true);

    formService
      .analyseColumnsCollaboratorsFile(formId, formData)
      .then(({ data: { fileName, headers, collaborators } }) => {
        formDataDispatch({
          type: 'FIND_FORM_DATA',
          collaboratorsFormData: {
            collaboratorsFile: formData.get('collaboratorsFile'),
          },
        });
        collaboratorsFileErrorDispatch({
          type: 'HANDLER_COLLABORATORS_FILE_ERROR',
          handlerError: {
            hasError: false,
            message: '',
          },
        });
        hasFileError = false;
        fileDispatch({
          type: 'HANDLER_FILE',
          file_collaborators: { fileName, headers, collaborators },
        });
        clearFieldSelected({ type: 'HANDLER_SELECTED_FIELDS', selectedFields: [] });
      })
      .catch((error) => {
        collaboratorsFileErrorDispatch({
          type: 'HANDLER_COLLABORATORS_FILE_ERROR',
          handlerError: {
            hasError: true,
            message: `Houve um erro ao carregar o arquivo "${
              formData.get('collaboratorsFile').name
            }"`,
          },
        });
        hasFileError = true;
        throw error;
      })
      .finally(() => {
        setLoading(false);

        if (hasFileError) {
          history.push('/admin/error-collaborators-import-validate', {
            fileName: formData.get('collaboratorsFile').name,
          });
        } else {
          history.push('/admin/map-collaborators');
        }
      });
  };

  const handleFile = (event) => {
    const [file] = event.target.files;
    setSelectedFileName(file.name);

    const formData = new FormData();
    formData.append('collaboratorsFile', file);

    analyseColumnsCollaboratorsFile(formData);
  };

  if (loading) {
    return (
      <Loading
        showLoadingLabel={true}
        title={selectedFileName}
        loadingLabel="Carregando arquivo…"
      />
    );
  }

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant="h1">Importar arquivo</Text>
      <Text variant="hintText">
        Utilize as instruções abaixo para preparar o arquivo que será importado.
      </Text>

      <Grid item xs={12} marginTop={'30px'}>
        <Grid item xs={12}>
          <Text variant="h4">FORMATOS ACEITOS</Text>
          <Text variant="hintText">.xlsx e .csv</Text>
        </Grid>

        {requiredFormFields.length > 0 && (
          <Grid item xs={12} marginTop={'30px'}>
            <Text variant="h4">COLUNAS OBRIGATÓRIAS</Text>
            {requiredFormFields.map((field, index) => (
              <Text variant="hintText" key={index} marginTop={1}>
                {field.name}
              </Text>
            ))}
          </Grid>
        )}

        {optionalFormFields.length > 0 && (
          <Grid item xs={12} marginTop={'30px'}>
            <Text variant="h4">COLUNAS OPCIONAIS</Text>
            {optionalFormFields.map((field, index) => (
              <Text variant="hintText" key={index} marginTop={1}>
                {field.name}
              </Text>
            ))}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} marginTop={'30px'}>
        <Text variant="h4">PLANILHA MODELO</Text>

        <Link href={TEMPLATE_SPREADSHEET_IMPORTING_EMPLOYEES} target="_blank">
          <Text variant="hintText" marginTop={1}>
            <img src={importSpreadsheetIcon} width={25} />
            <span style={styles.link}>Faça o download aqui</span>
          </Text>
        </Link>
      </Grid>

      <Grid item xs={12} marginTop={'30px'} textAlign="center">
        <ButtonComponent size="medium" onClick={() => fileInput.current.click()}>
          Selecionar arquivo
        </ButtonComponent>

        <input ref={fileInput} type="file" accept=".csv,.xlsx" onChange={handleFile} hidden />
      </Grid>
    </ContainerFullPage>
  );
}
