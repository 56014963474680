import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Img } from './styles';
import successImg from '../../../../../../assets/gifs/thumbs-up-icon.gif';
import { Text, ButtonComponent } from '../../../../../../components';
import { ContainerFullPageWithoutBackButton } from '../../../../../../styles/components';
import GlobalStyles from '../../../../../../styles/GlobalStyles';

function SuccessImportFileMessage(props) {
  const history = useHistory();
  const selectedDispatch = useDispatch();
  const listPreviewDispatch = useDispatch();
  const { title, buttonText, redirectTo } = props.location.state;

  const finishImport = () => {
    listPreviewDispatch({ type: 'HANDLER_LIST_PREVIEW', listPreview: [] });
    selectedDispatch({ type: 'HANDLER_SELECTED_FIELDS', selectedFields: [] });

    if (redirectTo === 'directMessage') {
      history.push('/admin/direct-message');
    } else if (redirectTo === 'mural') {
      history.push('/admin/mural');
    } else if (redirectTo === 'home') {
      history.push('/admin/home');
    } else {
      history.push('/admin/collaborators');
    }
  };

  return (
    <ContainerFullPageWithoutBackButton
      container
      spacing={0}
      sx={GlobalStyles.containerFullPageWithoutBackButton}
    >
      <Grid item xs={12}>
        <Img src={successImg}></Img>
      </Grid>
      <Grid item xs={12}>
        <Text variant='h1'textAlign='center'>{title}</Text>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <ButtonComponent size='medium' onClick={() => finishImport()}>
          {buttonText}
        </ButtonComponent>
      </Grid>
    </ContainerFullPageWithoutBackButton>
  );
}

export default SuccessImportFileMessage;
