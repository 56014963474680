import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

export const Img = styled('img')({
  height: 60,
  width: 60,
});

export const styles = createStyles({
  grid: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '20px',
    marginBottom: '10px',
  },
});
