import { FIND_COMPLAINTS, RESOLVE_COMPLAINT } from '../types';

const INITIAL_STATE = {
  newComplaints: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_COMPLAINTS:
      return { ...state, newComplaints: action.newComplaints };
    case RESOLVE_COMPLAINT:
      return { ...state, newComplaints: Math.max(state.newComplaints - 1, 0) };
    default:
      return state;
  }
};
