import { Box, Modal as MuiModal } from '@mui/material';
import GlobalStyles from '../../styles/GlobalStyles';

// TODO: set the size with parameters like "small", "medium" and "large"
const Modal = ({ width = 500, children, ...props }) => (
  <MuiModal
    {...props}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={GlobalStyles.modal} width={width} textAlign='center'>
      {children}
    </Box>
  </MuiModal>
);

export default Modal;
