import api from './Api';
import getAuthToken from './helpers';

class RequestRHService {
  constructor() {
    this.api = api;
  }

  getRequestsRH(page = 1, paginate = 10, status = undefined, order = 'DESC', startDate, finalDate) {
    const url = `/request-rh/get-request-rh?page=${page}&paginate=${paginate}&status=${status}&order=${order}&startDate=${startDate}&finalDate=${finalDate}`;
    return this.api.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  async resolveRequest(requestId, dispatch) {
    const url = `/request-rh/${requestId}/resolve-status-request`;
    return this.api.put(url, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }).then((result) => {
      dispatch({ type: 'RESOLVE_REQUEST' });
      return result;
    });
  }

  getNewRequests() {
    return this.api.get('/request-rh/get-amount-new-requests', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default RequestRHService;
