import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, MenuItem } from '@mui/material';

import CollaboratorService from '../../../../services/napoleon/CollaboratorService';
import SectorsService from '../../../../services/napoleon/SectorsService';
import { ButtonComponent, Link, Text } from '../../../../components';
import {
  DateInput,
  HelperText,
  Input,
  SelectInput,
} from '../../../../components/Form';
import { ContainerFullPage } from '../../../../styles/components';
import GlobalStyles from '../../../../styles/GlobalStyles';
import * as formElementNames from '../../../../constants/formElementNames';
import FIELDS from '../../../../constants/collaboratorFields';
import {
  valueNoSector,
  inputErrors,
  isDateField,
  isSectorField,
  sortByRequired,
  isCpfField,
} from './helpers';
import { infoIcon } from '../../../../assets/images/icons';
import { showSuccessToast, showErrorToast } from '../../../../redux/reducers/toastReducer';

const noValue = '-';

export default function AddCollaborator(props) {
  const collaboratorService = new CollaboratorService();
  const sectorsService = new SectorsService();
  const history = useHistory();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.userReducer.decodeToken);
  const formFields = useSelector((state) => (
    sortByRequired(state.formReducer.formElement.filter((message) => message.active))
  ));
  const [collaborator, setCollaborator] = useState(props.location.state?.collaborator);

  function getFieldValue(field) {
    const information = collaborator?.informations?.find((info) => info.name === field.name);

    if (!information || information.value === noValue) {
      return isDateField(field) ? null : undefined;
    }

    const { value } = information;

    if (typeof value === 'object' && value.id) {
      return value.id;
    }

    if (isDateField(field)) {
      const [year, month, day] = value.split('-');
      return new Date(Number(year), Number(month) - 1, Number(day));
    }

    return value;
  }

  const defaultData = {
    operation: (collaborator ? 'Atualizar' : 'Adicionar'),
    operationCompleted: (collaborator ? 'atualizado' : 'adicionado'),
    form: formFields.map((field) => ({
      ...field,
      value: getFieldValue(field),
    })),
  };

  const [operation, setOperation] = useState(defaultData.operation);
  const [operationCompleted, setOperationCompleted] = useState(defaultData.operationCompleted);
  const [form, setForm] = useState(defaultData.form);
  const [loading, setLoading] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [dateFieldError, setDateFieldError] = useState([]);

  const shouldSearchCollaborator = (field) => (
    !collaborator && isCpfField(field) && !inputErrors(field)
  );

  const searchCollaborator = (cpf) => {
    collaboratorService
      .getCollaboratorList(admin.user.companyId, 1, 'cpf', cpf)
      .then((response) => {
        const { data } = response;

        if (data && data.docs?.length > 0) {
          setCollaborator(data.docs[0]);
        }
      })
      .catch((error) => {
        dispatch(showErrorToast('Erro ao buscar colaborador. Tente novamente mais tarde'));
        setCollaborator(null);
        throw error;
      });
  };

  const handleChangeForm = useCallback(
    (value, index) => {
      setForm((prev) => {
        const newForm = [...prev];
        newForm[index] = { ...newForm[index], value };

        if (shouldSearchCollaborator(newForm[index])) {
          searchCollaborator(value);
        }

        return newForm;
      });
    },
    [setForm],
  );

  const handleDateFieldError = useCallback(
    (field, error) => {
      setDateFieldError((prev) => {
        const newError = [...prev];
        const idx = newError.findIndex((err) => err.fieldId === field.id);

        if (idx > -1) {
          if (error) {
            newError[idx] = { ...newError[idx], error };
            return newError;
          }

          newError.splice(idx, 1);
          return newError;
        }

        if (error) {
          newError.push({ fieldId: field.id, error });
          return newError;
        }

        return newError;
      });
    },
    [setDateFieldError],
  );

  const navigateToAddSector = () => history.push('/admin/sectors');

  const fieldInput = (field, index) => {
    if (isDateField(field)) {
      return (
        <>
          <DateInput
            value={field.value}
            onChange={(date) => handleChangeForm(date, index)}
            onError={(err) => handleDateFieldError(field, err)}
            placeholderText={FIELDS[field.name]?.placeholder}
            disableFuture
          />
          {dateFieldError.some((error) => error.fieldId === field.id) && (
            <HelperText>Data inválida</HelperText>
          )}
        </>
      );
    }

    let error = inputErrors(field);

    if (isSectorField(field)) {
      // It shows error message when there are no sectors registered
      if (!error) {
        error = sectors.length === 0 && field.required && 'Campo obrigatório';
      }

      return (
        <>
          {sectors.length === 0 && (
            <Text
              variant="hintTextLabel"
              style={GlobalStyles.textCenter}
              marginBottom={1.5}
              onClick={navigateToAddSector}
            >
              <Link style={GlobalStyles.textDcNone}>
                <img src={infoIcon} width={15} alt="Information icon" /> &nbsp;
                <span style={GlobalStyles.textDcUnderline}>Adicione setores</span>
              </Link>
            </Text>
          )}

          <SelectInput
            id="simple-select-standard"
            value={field.value || valueNoSector}
            onChange={(e) => handleChangeForm(e.target.value, index)}
            error={!!error}
          >
            {sectors.length > 0 ? (
              [
                <MenuItem value={valueNoSector} key="no-sector">
                  <em>Nenhum</em>
                </MenuItem>,
                ...sectors.map((sector, key) => (
                  <MenuItem value={sector.id} key={key}>
                    {sector.name}
                  </MenuItem>
                )),
              ]
            ) : (
              <MenuItem value={valueNoSector}>
                <em>Nenhum setor cadastrado</em>
              </MenuItem>
            )}
          </SelectInput>
          {!!error && <HelperText>{error}</HelperText>}
        </>
      );
    }

    const fieldMask = FIELDS[field.name]?.mask;

    // STRING FIELDS
    return (
      <Input
        type={FIELDS[field.name]?.type || 'text'}
        value={field.value}
        placeholder={FIELDS[field.name]?.placeholder}
        mask={fieldMask}
        onAccept={fieldMask ? (value) => handleChangeForm(value, index) : undefined}
        onChange={fieldMask ? undefined : (event) => handleChangeForm(event.target.value, index)}
        inputProps={FIELDS[field.name]?.inputProps}
        error={!!error}
        helperText={error}
        autoComplete="off"
        disabled={collaborator && isCpfField(field)}
      />
    );
  };

  function isButtonDisabled() {
    // Checks if the fields has been filled in
    if (
      form.some((field) => field.required && (!field.value || field.value.toString().trim() === ''))
    ) {
      return true;
    }

    // Checks for date errors
    if (dateFieldError.length > 0) return true;

    // Additional checks
    const errors = [];
    form.forEach((field) => {
      const hasError = inputErrors(field);
      if (hasError) {
        errors.push(hasError);
      }
    });
    return errors.length > 0;
  }

  const handleSubmit = () => {
    if (isButtonDisabled() || loading) return;

    setLoading(true);

    function getValue(field) {
      if (isSectorField(field) && field.value === valueNoSector) {
        return '';
      }

      return (isDateField(field) ? field.value?.toISOString().split('T')[0] : field.value) || '';
    }

    const formElementsValue = form
      .filter((field) => field.id > 0)
      .map((field) => ({
        formElementId: field.id,
        value: getValue(field),
      }));

    const data = {
      companyId: admin.user.companyId,
      cpf: form.find((field) => field.name === formElementNames.CPF).value,
      identification: form.find((field) => field.name === formElementNames.IDENTIFICACAO).value,
      formElementsValue,
    };

    collaboratorService
      .createCollaborator(data)
      .then(() => {
        dispatch(showSuccessToast(`Colaborador ${operationCompleted} com sucesso!`));
        history.push('/admin/collaborators');
      })
      .catch(() => {
        dispatch(showErrorToast(`Erro ao ${operation.toLowerCase()} colaborador. Tente novamente mais tarde`));
        setLoading(false);
      });
  };

  useEffect(() => {
    setOperation(defaultData.operation);
    setOperationCompleted(defaultData.operationCompleted);
    setForm(defaultData.form);
  }, [collaborator]);

  useEffect(() => {
    sectorsService
      .getSectors(admin.user.companyId, 1, 1000)
      .then((response) => {
        const { sectors: data } = response.data;

        if (data && data.length > 0) {
          setSectors(data);
        }
      })
      .catch((error) => {
        dispatch(showErrorToast('Erro ao buscar setores. Tente novamente mais tarde'));
        throw error;
      });
  }, []);

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant="h1">{operation} colaborador</Text>
      <Text variant="label" marginTop={1} marginBottom={1}>
        Campos marcados com * são obrigatórios.
      </Text>

      {form.map((field, idx) => (
        <FormControl variant="standard" fullWidth key={idx}>
          <Text variant="h4" marginTop={4} textTransform="uppercase">
            {field.name}
            {field.required && '*'}
          </Text>

          {FIELDS[field.name]?.subtitle && (
            <Text variant="hintTextLabel" marginTop={0.5} marginBottom={1}>
              {FIELDS[field.name].subtitle}
            </Text>
          )}

          {fieldInput(field, idx)}
        </FormControl>
      ))}

      <Grid item xs={12} textAlign="center" marginTop={4} marginBottom={2}>
        <ButtonComponent
          size="medium"
          onClick={handleSubmit}
          loading={loading}
          disabled={isButtonDisabled() || loading}
        >
          {operation}
        </ButtonComponent>
      </Grid>
    </ContainerFullPage>
  );
}
