import createStyles from '@mui/styles/createStyles';

export const styles = createStyles({
  select: {
    width: 300,
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
