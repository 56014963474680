/* eslint-disable no-param-reassign */
function percentage(fraction) {
  if (!fraction) return null;

  if (fraction === '0/0') return 0;
  const numerator = fraction.split('/')[0];
  const denominator = fraction.split('/')[1];
  return Math.round((numerator / denominator) * 100);
}

function isValidDate(dateString) {
  const date = dateString.substring(0, 10);

  // First check for the pattern
  // eslint-disable-next-line
  if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = date.split('-');
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function isValidEmail(email) {
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regexEmail.test(email);
}

function isValidCPF(cpf) {
  cpf = cpf.replace(/[^\d]/g, '');

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += Number(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== Number(cpf.substring(9, 10))) return false;

  soma = 0;

  for (let i = 1; i <= 10; i++) {
    soma += Number(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== Number(cpf.substring(10, 11))) return false;

  return true;
}

function isValidCellphone(phoneNumber) {
  const phoneRegex = /^\(\d{2,3}\) \s?\d{4,5}-\d{4}$/; // Ex: (01) 23456-7891
  return phoneRegex.test(phoneNumber);
}

export {
  percentage,
  isValidDate,
  isEmpty,
  isValidEmail,
  isValidCPF,
  isValidCellphone,
};
