import makeStyles from '@mui/styles/makeStyles';
import COLORS from '../../../styles/Colors';

const errorState = (defaultColor) => ({
  borderColor: ({ error }) => (error ? COLORS.RED : defaultColor),
  borderWidth: 1,
});

const useStyles = makeStyles({
  selectOptions: {
    minWidth: 145,
    borderWidth: 1,
    backgroundColor: COLORS.WHITE,
    '&:before': {
      ...errorState(COLORS.SECONDARY),
    },
    '&:after': {
      ...errorState(COLORS.PRIMARY),
    },
    '&:not(.Mui-disabled):hover::before': {
      ...errorState(COLORS.PRIMARY),
    },
  },
  icon: {
    fill: COLORS.SECONDARY,
  },
  selected: {
    backgroundColor: COLORS.WHITE,
  },
});

export default useStyles;
