import createStyles from '@mui/styles/createStyles';
import GlobalStyles from '../../../../styles/GlobalStyles';

const styles = createStyles({
  modal: {
    ...GlobalStyles.modal,
    width: 500,
  },
  warningIcon: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
});

export default styles;
