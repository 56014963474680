import api from './Api';
import getAuthToken from './helpers';

class CompanyService {
  constructor() {
    this.api = api;
  }

  getCompanyForm(companyId) {
    return this.api.get(`/companies/${companyId}/forms`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  getStatistics(companyId) {
    return this.api.get(`/companies/${companyId}/statistics`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default CompanyService;
