import styled from '@mui/system/styled';
import { Grid, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import COLORS from './Colors';
import theme from './theme';

/* STYLES USED IN CONTAINERS */
export const FlexDirectionRowCenter = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
});

export const ContainerFullPage = styled('div')({
  marginRight: 'auto',
  marginLeft: 'auto',
  paddingTop: '40px',
  paddingBottom: '40px',
});

export const ContainerFullPageWithoutBackButton = styled(Grid)({
  minHeight: '100%',
  paddingBottom: 40,
  flexDirection: 'column',
  alignItems: 'center',
});

/* STYLES USED IN TABLES */
export const HeaderTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    ...theme.typography.h5,
    backgroundColor: COLORS.LIGHT,
  },
});

export const TableRowStyled = styled(TableRow)({
  '&:hover': {
    backgroundColor: COLORS.LIGHT,
    cursor: 'pointer',
  },
});
