import createStyles from '@mui/styles/createStyles';

export const styles = createStyles({
  spacingLeft: {
    ml: { md: 1 },
  },
  optionsPopover: {
    maxHeight: 180,
  },
});
