import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

export const ContainerTitle = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
});

export const styles = createStyles({
  justifyContentRowSpaceEvenly: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'start',
    flexDirection: 'row',
  },
  justifyContentColumnStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  statisticsContent: {
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '15px',
    paddingBottom: '20px',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%)',
  },
});
