import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import Text from '../Text';
import styles from './styles';

export default function Loading({ loadingLabel, title }) {
  return (
    <Grid
      container
      rowSpacing={2.5}
      direction="column"
      alignItems="center"
      style={styles.container}
    >
      {
        loadingLabel && (
          <Grid item xs={12}>
            <Text variant='h3'>{loadingLabel}</Text>
          </Grid>
        )
      }

      <Grid item xs={12}>
        <CircularProgress color='primary' />
      </Grid>

      {
        title && (
          <Grid item xs={12}>
            <Text variant='hintText'>{title}</Text>
          </Grid>
        )
      }
    </Grid>
  );
}
