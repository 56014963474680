import { createStyles, makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import COLORS from '../../../styles/Colors';
import { hexToRgb } from '../../../assets/jss/general';

const StyledInput = styled(TextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const styles = createStyles({
  helperText: {
    color: COLORS.RED,
  },
});

const useStyles = makeStyles({
  styles: (props) => ({
    borderWidth: 1,
    '& .MuiInput-underline:before': {
      borderBottomColor: props.error ? COLORS.RED : COLORS.SECONDARY,
      borderWidth: 1,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: props.error ? COLORS.RED : COLORS.PRIMARY,
      borderWidth: 1,
    },
    '&:hover .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: props.error ? COLORS.RED : COLORS.PRIMARY,
      borderWidth: 1,
    },
    '& .Mui-disabled:before': {
      borderBottomStyle: 'solid',
      borderBottomColor: `rgba(${hexToRgb(COLORS.SECONDARY_LIGHT)}, 0.20)`,
      borderWidth: 1,
    },
  }),
});

export default StyledInput;

export { styles, useStyles };
