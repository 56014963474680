import { useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { ContainerRemember, styles } from './styles';
import { Text, Link, ButtonComponent } from '..';
import Input from '../Form/Input';
import { RECOVER_PASSWORD, HIRE } from '../../constants/links';

const LoginForm = ({
  emailNull,
  onClick,
  passwordNull,
  valueEmail,
  onChangeEmail,
  emailLabelError,
  PassWord,
  onChangePass,
  passWordLabelError,
  validateEmail,
  validatePassword,
  showLoading,
}) => {
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <FormControl sx={styles.textField} variant="standard">
        <Text variant='h3' color='primary'>
          Bem-vindo novamente!
        </Text>
        <Text variant='hintText' sx={styles.marginBottom}>
          Não possui uma conta? <Link href={HIRE}>Comece agora.</Link>
        </Text>

        <Input
          error={emailNull}
          placeholder="E-mail"
          value={valueEmail}
          onChange={onChangeEmail}
          type="email"
          name="email"
          helperText={emailLabelError}
          onKeyUpCapture={validateEmail}
        />
      </FormControl>

      <FormControl
        error={passwordNull}
        sx={styles.textField}
        variant="standard"
      >
        <Input
          type={values.showPassword ? 'text' : 'password'}
          placeholder="Senha"
          value={PassWord}
          onChange={onChangePass}
          onKeyUpCapture={validatePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText id="component-error-text">{passWordLabelError}</FormHelperText>
      </FormControl>

      <ContainerRemember>
        <ButtonComponent
          size="large"
          loading={showLoading}
          onClick={onClick}
          sx={styles.marginBottom}
        >
          Entrar
        </ButtonComponent>

        <Text variant='hintText'>
          <Link href={RECOVER_PASSWORD}> Esqueci minha senha.</Link>
        </Text>
      </ContainerRemember>
    </>
  );
};

export default LoginForm;
