import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';
import history from '../../common/history';

const persistConfig = {
  key: 'roots',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, compose(
  applyMiddleware(
    routerMiddleware(history),
  ),
));
const persistor = persistStore(store);

export {
  store,
  persistor,
};
