import { isValidCellphone, isValidCPF, isValidEmail } from '../../../../utils';
import * as elementType from '../../../../constants/elementTypeNames';
import * as formElementNames from '../../../../constants/formElementNames';

const valueNoSector = 0;

// Sort fields by `required` and `name` property
const sortByRequired = (array) => (
  array.sort((a, b) => {
    if (a.required !== b.required) {
      return b.required - a.required;
    }
    return a.name.localeCompare(b.name);
  })
);

const isDateField = (field) => field.elementType.name === elementType.BIRTH_DATE;

const isCpfField = (field) => field.name === formElementNames.CPF;

const isSectorField = (field) => field.name === formElementNames.SETOR;

const inputErrors = (field) => {
  if (
    field.required
    && (field.value?.toString().trim() === '' || (isSectorField(field) && field.value === valueNoSector))
  ) {
    return 'Campo obrigatório';
  }

  if (isCpfField(field) && field.value && !isValidCPF(field.value)) {
    return 'CPF inválido';
  }

  if (
    field.elementType.name === elementType.MOBILE_PHONE
    && field.value && !isValidCellphone(field.value)
  ) {
    return `${field.name} inválido`;
  }

  if (field.elementType.name === elementType.EMAIL && field.value && !isValidEmail(field.value)) {
    return `${field.name} inválido`;
  }

  return null;
};

export {
  valueNoSector,
  sortByRequired,
  isDateField,
  isCpfField,
  isSectorField,
  inputErrors,
};
