import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { ButtonComponent, Text } from '../../../../components';
import { ContainerFullPage } from '../../../../styles/components';
import GlobalStyles from '../../../../styles/GlobalStyles';
import styles, { Select, Forms } from './styles';

const defaultOpt = 'default';

export default function MapCollaborators() {
  const history = useHistory();
  const selectedDispatch = useDispatch();
  const listPreviewDispatch = useDispatch();

  const headers = useSelector((state) => state.formReducer.file_collaborators.headers);
  const collaborators = useSelector((state) => state.formReducer.file_collaborators.collaborators);
  const formElements = useSelector((state) => state.formReducer.formElement);
  const selectedFieldsFromState = useSelector((state) => state.formReducer.selectedFields);

  const options = [];
  const fields = [];
  const newHeaderSelected = [...headers.map((item) => ({ value: item, fieldColumnName: defaultOpt }))];

  if (selectedFieldsFromState && selectedFieldsFromState.length > 0) {
    selectedFieldsFromState.forEach((selected) => {
      options.push({ value: selected.formFieldName, label: selected.formFieldName });
      fields.push({ formFieldName: selected.formFieldName, selected: selected.selected, required: selected.required });

      const idx = newHeaderSelected.findIndex((header) => header.value === selected.fieldColumnName);
      if (idx > -1) {
        newHeaderSelected[idx] = { ...newHeaderSelected[idx], fieldColumnName: selected.formFieldName };
      }
    });
  } else {
    formElements.forEach((formElement) => {
      if (formElement.active) {
        options.push({ value: formElement.name, label: formElement.name });
        fields.push({ formFieldName: formElement.name, selected: false, required: formElement.required });
      }
    });
  }

  const optionsSelect = options;
  const [selectedFields, setSelectedFields] = useState(fields);
  const [headerSelected, setHeaderSelected] = useState(newHeaderSelected);

  const handleChange = (event, headerIndex) => {
    const newValue = event.target.value;
    setHeaderSelected((prevHeader) => {
      const newHeaders = [...prevHeader];
      const oldHeaders = newHeaders[headerIndex];
      newHeaders[headerIndex] = { ...newHeaders[headerIndex], fieldColumnName: newValue };

      setSelectedFields((prevFields) => {
        const newSelectedFields = [...prevFields];
        /* When the user selects the option `Não mapear`, should search for the value it was before,
          in order to find the correct index in the array, to update the `selected` property */
        const searchValue = newValue || oldHeaders.fieldColumnName;
        const indexToUpdate = newSelectedFields.findIndex((field) => field.formFieldName === searchValue);
        newSelectedFields[indexToUpdate] = { ...newSelectedFields[indexToUpdate], selected: !!newValue };

        // When the user changes the selected option, should search for the old selected property to update its `selected` property
        if (searchValue) {
          const indexOldSelected = newSelectedFields.findIndex(
            (field) => field.formFieldName === oldHeaders.fieldColumnName,
          );
          if (indexOldSelected > -1) {
            newSelectedFields[indexOldSelected] = { ...newSelectedFields[indexOldSelected], selected: false };
          }
        }

        return newSelectedFields;
      });

      return newHeaders;
    });
  };

  // Prepares the data to be used in page PreviewList and server
  const handleSubmit = () => {
    const listPreview = [];
    const updatedSelectedFields = [...selectedFields.map((selected) => ({ ...selected, fieldColumnName: '' }))];

    headerSelected.forEach((header) => {
      if (header.fieldColumnName) {
        collaborators.forEach((collaborator) => {
          if (collaborator[header.value]) {
            listPreview.push({
              field: header.value,
              value: collaborator[header.value],
            });
          }
        });

        const idx = updatedSelectedFields.findIndex((selected) => selected.formFieldName === header.fieldColumnName);
        if (idx > -1) {
          updatedSelectedFields[idx] = { ...updatedSelectedFields[idx], fieldColumnName: header.value };
        }
      }
    });

    listPreviewDispatch({ type: 'HANDLER_LIST_PREVIEW', listPreview });
    selectedDispatch({ type: 'HANDLER_SELECTED_FIELDS', selectedFields: updatedSelectedFields });
    history.push('/admin/preview-list');
  };

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>Mapeie as colunas do arquivo</Text>
      <Text variant='hintText' style={GlobalStyles.smallMarginTop}>
        Selecione as colunas correspondentes utilizando os seletores abaixo.
      </Text>

      {headerSelected.map((header, index) => (
        <Forms
          container
          key={index}
          last={index === headerSelected.length - 1 ? 1 : 0}
          rowSpacing={2}
        >
          <Grid item xs={12} sm={5}>
            <Text variant='h4'>Nome da coluna</Text>
            <Text variant='hintText'>{header.value}</Text>

            <Text variant='h4' style={GlobalStyles.smallMarginTop}>Dados nessa coluna</Text>
            {collaborators.map((collaborator, key) => (
              <Text variant='hintText' key={key} lineHeight={1.5}>{collaborator[header.value]}</Text>
            ))}
          </Grid>

          <Grid item xs={12} sm={7}>
            <Text variant='h4'>Selecione a coluna correspondente</Text>
            <Select onChange={(event) => handleChange(event, index)} value={header.fieldColumnName}>
              {
                optionsSelect.map((prop, opIndex) => (
                  <option
                    key={opIndex}
                    value={prop.label}
                    style={
                      headerSelected.findIndex((selected) => selected.fieldColumnName === prop.value) >= 0
                        ? styles.none
                        : styles.flex
                    }
                  >
                    {prop.label}
                  </option>
                ))
              }
              <option value={defaultOpt}>Não Mapear</option>
            </Select>
          </Grid>
        </Forms>
      ))}

      <Grid item xs={12} textAlign='center'>
        <ButtonComponent
          size='medium'
          disabled={selectedFields.filter((option) => option.required && !option.selected).length > 0}
          onClick={handleSubmit}
        >
          Próximo passo
        </ButtonComponent>
      </Grid>
    </ContainerFullPage>
  );
}
