const FIELDS = {
  CPF: {
    type: 'tel',
    placeholder: 'Ex: 123.456.789-10',
    mask: '000.000.000-00',
    subtitle: 'Dado usado para o login.',
    validations: {
      hasWrongFormat: {
        title:
          'A coluna CPF deve ter 11 caracteres numéricos, com ou sem pontuação, no formato texto e os CPFs devem ser válidos.',
        message: 'Os CPFs abaixo são inválidos:',
      },
      hasDuplicate: {
        title: 'Há CPFs duplicados.',
      },
    },
  },
  Identificação: {
    placeholder: 'Ex: 12345678910',
    inputProps: { maxLength: 200 },
    subtitle:
      'Dado usado para o login. Pode-se utilizar a matrícula do colaborador, caso não possua matrícula recomenda-se usar o CPF.',
  },
  'Nome Completo': {
    placeholder: 'Ex: João da Silva',
    inputProps: { maxLength: 200 },
  },
  Celular: {
    type: 'tel',
    placeholder: 'Ex: 11998887777',
    mask: '(00) 00000-0000',
    validations: {
      hasWrongFormat: {
        title: 'A coluna Celular deve ter até 11 caracteres numéricos no formato 01234567891 ou até 15 caracteres no formato texto e no padrão (01) 23456-7891',
        message: 'Os celulares abaixo são inválidos:',
      },
    },
  },
  'Data de nascimento': {
    type: 'date',
    placeholder: 'Ex: 01/01/1980',
    validations: {
      hasWrongFormat: {
        title: 'A coluna Data de nascimento deve estar no formato data.',
        message: 'As datas de nascimento abaixo são inválidas:',
      },
    },
  },
  Email: {
    type: 'email',
    placeholder: 'Ex: exemplo@exemplo.com',
    inputProps: { maxLength: 200 },
    validations: {
      hasWrongFormat: {
        title: 'A coluna Email deve conter um endereço de e-mail válido, no formato texto.',
        message: 'Os emails abaixo são inválidos:',
      },
    },
  },
  Setor: {},
};

export default FIELDS;
