import axios from 'axios';
import config from '../../config';
import { store } from '../../redux/store';

const { baseURL } = config.napoleonApi;

const api = axios.create({
  baseURL,
});

/**
 * When the token expires admin access is removed and it is redirected to login
 */
api.interceptors.response.use((response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && error.response.data.message === 'Unauthorized') {
      store.dispatch({ type: 'SESSION_EXPIRED', sessionExpired: true });
    }
    return Promise.reject(error);
  });

export default api;
