import illustrationGear from '../../../../assets/gifs/gears.gif';

function NewForm() {
  return (
    <div
    style={{
      height: '95vh',
      width: '100%',
      backgroundColor: '#fff',
      marginTop: '2.5vh',
      borderRadius: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div
      style={{
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <img style={{ width: '200px' }} src={illustrationGear}/>
        <div
        style={{
          color: 'rgba(0, 0, 0, 0.7)',
          fontSize: '16px',
          fontWeight: '700',
          fontFamily: 'Montserrat , sans-serif',
        }}>
          Você não possui um formulário configurado.</div>
          <div
          style={{
            color: 'rgba(0, 0, 0, 0.7)',
            fontWeight: '400',
            fontFamily: 'Montserrat , sans-serif',
            fontSize: '14px',
          }}>
            Se você necessita acessar a lista de colaboradores fale com o administrador desta
            conta.
            </div>
      </div>
    </div>
  );
}

export default NewForm;
