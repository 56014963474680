import createStyles from '@mui/styles/createStyles';

const styles = createStyles({
  modal: {
    overflow: 'scroll',
    display: 'block',
    width: '90%',
    height: '90%',
  },
  messageDescription: {
    marginTop: 15,
    marginBottom: 15,
  },
});

export default styles;
