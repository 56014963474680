import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';
import GlobalStyles from '../../styles/GlobalStyles';

const ITEM_OPTIONS_HEIGHT = 48;

export const Img = styled('img')({
  height: 22,
  width: 22,
});

export const ImgMessage = styled('img')({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: 60,
  width: 60,
  cursor: 'pointer',
});

export const styles = createStyles({
  justifyContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  muralContent: {
    paddingTop: 10,
  },
  statusCell: {
    fontSize: 12,
    textAlign: 'center',
  },
  messageOptionsFilterSpacing: {
    paddingBottom: 5,
  },
  spacingLeft: {
    ml: { sm: 1 },
  },
  optionsPopover: {
    maxHeight: ITEM_OPTIONS_HEIGHT * 4.5,
    width: '20ch',
  },
  modal: {
    ...GlobalStyles.modal,
    width: 400,
  },
  modalDeleteText: {
    marginTop: 10,
    marginBottom: 15,
  },
  modalDetail: {
    ...GlobalStyles.modal,
    overflow: 'scroll',
    display: 'block',
    width: '90%',
    height: '90%',
  },
  messageDescription: {
    marginTop: 15,
    marginBottom: 15,
  },
  marginTop: {
    marginTop: 10,
  },
  closeIconModalImage: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 9,
  },
  collaboratorName: {
    maxWidth: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
});
