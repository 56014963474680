// Design System colors. Ref: https://www.figma.com/file/Spdy6yQ94Dufmol7vTnP0J/R%C3%A1pidoRH---App-V2?node-id=0%3A1
import { hexToRgb } from '../assets/jss/general';

const PRIMARY_LIGHT = '#e1eacd';

const COLORS = Object.freeze({
  // Design System colors
  PRIMARY: '#01352c',
  PRIMARY_LIGHT,
  SECONDARY: '#000000',
  SECONDARY_LIGHT: '#333333',
  LIGHT: '#f6f6f6',
  DANGER: '#ffc436',

  INACTIVE_LIGHT: '#dadada',
  SELECTION_STATUS: `rgba(${hexToRgb(PRIMARY_LIGHT)}, 0.20)`,

  // Default Colors
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  RED: '#FF0000',
});

export default COLORS;
