import React from 'react';
import Text from '../Text';
import { Container, styles } from './styles';

export default function RadioButton({ selected, text, onClick }) {
  const stylesText = selected ? styles.selected : styles.deselected;

  return (
    <Container selected={selected} onClick={onClick}>
      <Text variant='hintText' component="span" style={stylesText}>
        {text}
      </Text>
    </Container>
  );
}
