import createStyles from '@mui/styles/createStyles';
import COLORS from '../../styles/Colors';

const styles = createStyles({
  container: {
    minHeight: 'calc(100vh - 50px)',
    background: COLORS.WHITE,
    paddingTop: '100px',
    paddingBottom: '100px',
  },
});

export default styles;
