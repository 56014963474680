import styled from '@mui/system/styled';
import COLORS from '../../styles/Colors';

const StyledLink = styled('a')(({ color }) => ({
  cursor: 'pointer',
  color: color ?? COLORS.PRIMARY,
  '&:hover': {
    color: color ?? COLORS.PRIMARY,
  },
  '&:active': {
    color: color ?? COLORS.PRIMARY,
  },
  '&:visited': {
    color: color ?? COLORS.PRIMARY,
  },
}));

export default StyledLink;
