import moment from 'moment';
import * as formElementNames from '../../constants/formElementNames';

const noValue = '-';

export default function useCollaborator() {
  function getInfo({ informations }, formElement) {
    if (formElement === formElementNames.SETOR) {
      const sector = informations.find((info) => info.name === formElementNames.SETOR);
      if (sector) {
        const { name } = sector.value;
        return name || sector.value;
      }
      return noValue;
    }

    if (formElement === formElementNames.DATA_DE_NASCIMENTO) {
      const information = informations.find((info) => info.name === formElementNames.DATA_DE_NASCIMENTO);
      if (!information) return noValue;
      const dateBirth = information.value;
      const isValid = moment(dateBirth, 'YYYY-MM-DD', true).isValid();
      return isValid ? moment(dateBirth).format('DD/MM/YYYY') : dateBirth;
    }

    const information = informations.find((info) => info.name === formElement);
    return information ? information.value : noValue;
  }

  return { getInfo };
}

export { noValue };
