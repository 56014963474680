import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import styles, { Img } from './styles';
import GlobalStyles from '../../../../../../styles/GlobalStyles';
import { SUPPORT } from '../../../../../../constants/links';
import { iconErrorIcon } from '../../../../../../assets/images/icons';
import { ContainerFullPageWithoutBackButton } from '../../../../../../styles/components';
import { Text, ButtonComponent, Link } from '../../../../../../components';

function ErrorImportFileMessage() {
  const history = useHistory();
  const fileName = useSelector((state) => state.formReducer.file_collaborators.fileName);
  const collaboratorsFileErrorDispatch = useDispatch();

  const resetState = () => {
    collaboratorsFileErrorDispatch({
      type: 'HANDLER_COLLABORATORS_FILE_ERROR',
      handlerError: {
        hasError: false,
        message: '',
      },
    });
    history.push('/admin/collaborators-import');
  };

  function message() {
    if (fileName) {
      return `Houve um erro ao carregar o arquivo "${fileName}"`;
    }

    return 'Não foi possível importar a lista de colaboradores. Tente novamente.';
  }

  return (
    <ContainerFullPageWithoutBackButton
      container
      spacing={0}
      sx={GlobalStyles.containerFullPageWithoutBackButton}
    >
      <Grid item xs={12}>
        <Img src={iconErrorIcon} />
      </Grid>
      <Grid item xs={12}>
        <Text variant="h2" color="secondary" lineHeight={2}>
          {message()}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant="hintText">
          Este arquivo parece estar em branco ou possui um formato não compatível.
        </Text>
      </Grid>

      <Grid item xs={12} paddingTop={3} style={styles.buttonsContainer}>
        <Grid container justifyContent="space-evenly" gap={2} alignItems="center">
          <Link href={SUPPORT} target="_blank" style={styles.link}>
            <ButtonComponent size="medium" variant="outlined">
              Falar com suporte
            </ButtonComponent>
          </Link>

          <ButtonComponent size="medium" onClick={resetState}>
            Tentar novamente
          </ButtonComponent>
        </Grid>
      </Grid>
    </ContainerFullPageWithoutBackButton>
  );
}

export default ErrorImportFileMessage;
