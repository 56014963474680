import Text from '../../Text';
import COLORS from '../../../styles/Colors';

const HelperText = ({ children }) => (
  <Text variant="hintTextLabel" color={COLORS.RED}>
    {children}
  </Text>
);

export default HelperText;
