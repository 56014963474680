import PropTypes from 'prop-types';
import StyledLink from './styles';

const Link = (props) => (
  <StyledLink {...props}>{ props.children }</StyledLink>
);

Link.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
};

export default Link;
