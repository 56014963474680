import createStyles from '@mui/styles/createStyles';
import GlobalStyles from '../../styles/GlobalStyles';

const styles = createStyles({
  input: {
    width: '100%',
  },
  containerSearchButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: { xs: 'flex-end', sm: 'flex-start' },
    alignItems: 'flex-end',
    marginTop: 2,
    marginBottom: 2,
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    ...GlobalStyles.modal,
    width: 400,
  },
  inputRegisterSector: {
    marginBottom: 20,
    width: '100%',
  },
  tableRow: {
    cursor: 'text',
  },
  deleteIcon: {
    marginLeft: 10,
  },
});

export default styles;
